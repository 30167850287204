import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useItem from "../../services/queries/useItem";
import toRibuan from "../../utils/toRibuan";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { BlobProvider } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BallotIcon from "@mui/icons-material/Ballot";
import {
    Box,
    Dialog,
    DialogTitle,
    Grid,
    Stack,
    Typography,
    IconButton,
    DialogContent,
    FormControlLabel,
    Checkbox,
    Button,
    Autocomplete,
    TextField,
    Chip,
    Link,
    InputAdornment,
    FormHelperText,
    FormControl,
    ToggleButtonGroup,
    ToggleButton,
} from "@mui/material";
import {
    IFaktur,
    InputPembayaranResponse,
    IOptions,
    ModalUtangField,
    ParamsGetCoa,
    ParamsGetItem,
    ParamsGetPelanggan,
    ParamsGetUkm,
    TipeStruk,
} from "../../constants/types";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import ModalAlertPro from "../ModalAlertPro/ModalAlertPro";
import useFindMetodePembayaran from "../../services/queries/useFindMetodePembayaran";
import { ArrowDropDown, Print } from "@mui/icons-material";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import { IconContext } from "react-icons";
import { GiMoneyStack, GiWallet } from "react-icons/gi";
import ReceiptIcon from "@mui/icons-material/Receipt";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import usePelanggan from "../../services/queries/usePelanggan";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import useCoa from "../../services/queries/useCoa";
import "moment/locale/id";
import formatHarga from "../../utils/formatHarga";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";

registerLocale("id", id);
setDefaultLocale("id");
moment.locales();
moment.locale("id");

interface IModalKonfirmasiBayarProps {
    refetch: () => void;
    persentaseDiskon?: number | null;
    diskon?: number;
    persentasePajak?: number | null;
    pajak?: number;
    totalTagihan: number;
    uangDibayar: number;
    kembalian: number;
    kasir: string;
    sales: IOptions | null;
    subTotal: number;
    isNonTunai: boolean;
    keteranganBeli: string;
    resetKeteranganBeli: () => void;
}

enum ModePembayaran {
    TUNAI = "tunai",
    TRANSFER = "transfer",
    UTANG = "utang",
    PO = "preorder",
}

type ToggleKirimStruk = "email" | "whatsapp";

export const ModalKonfirmasiBayar = (props: IModalKonfirmasiBayarProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] =
        React.useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm } = useUkm(paramsUkm);

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
        kategori: "",
    };

    const [printStruk, setPrintStruk] = useState(false);
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [metodePelanggan, setMetodePelanggan] = useState("");
    const [paramsItem, setParamsItem] = useState<ParamsGetItem>(initialParams);
    const [totalHargaSeluruhItem, setTotalHargaSeluruhItem] = useState(0);
    const { refetch: refetchItem } = useItem(paramsItem);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [toggleModePembayaran, setToggleModePembayaran] = useState(
        ModePembayaran.TUNAI,
    );
    const [selectedMetode, setSelectedMetode] = useState<IOptions | null>(null);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [toggleKirimStruk, setToggleKirimStruk] =
        useState<ToggleKirimStruk>("whatsapp");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStruk") ?? TipeStruk.Mobile,
    );
    const [tipeStrukTagihan, setTipeStrukTagihan] = useState<string | null>(
        localStorage.getItem("tipeStrukTagihan") ?? TipeStruk.Mobile,
    );

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStruk", newTipeStruk);
        }
    };

    const handleTipeStrukTagihan = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStrukTagihan(newTipeStruk);
            localStorage.setItem("tipeStrukTagihan", newTipeStruk);
        }
    };

    const pilihWhatsapp = () => {
        setToggleKirimStruk("whatsapp");
        setEmail("");
    };

    const pilihEmail = () => {
        setToggleKirimStruk("email");
        setEmail("");
    };

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { data: metodePembayaran, isLoading: isLoadingMetodePembayaran } =
        useMetodePembayaran(ukmId);

    const { data: findMetodePembayaran } = useFindMetodePembayaran(
        Number(selectedMetode?.id),
    );

    const mappedMetodePembayaran = metodePembayaran
        ?.filter((mp) => mp.id !== 0)
        .map((li) => ({
            id: li.id,
            nama: li.namaMetode,
        }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));

    const initialParamsPelanggan = {
        size: 300,
        page: 1,
        search: "",
    };
    const [paramsPelanggan, setParamsPelanggan] =
        React.useState<ParamsGetPelanggan>(initialParamsPelanggan);

    const { data: pelanggan, isLoading: isLoadingPelanggan } =
        usePelanggan(paramsPelanggan);

    const mappedPelanggan = pelanggan?.content.map((li) => ({
        id: li.id,
        nama: li.nama,
    }));

    const initialParamsCoa = {
        size: 300,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsCoa, setParamsCoa] = useState<ParamsGetCoa>(initialParamsCoa);
    const { data: coa, isLoading: isLoadingCoa } = useCoa(paramsCoa);
    const mappedCoa = coa?.content
        .filter((c) => c.header !== 1)
        .map((li) => ({
            id: li.id,
            noAkun: li.noAkun,
            namaAkun: li.namaAkun,
        }));

    const iconSize = isTabletScreen ? 48 : 56;

    const {
        cart,
        totalHarga,
        isOpenModalKonfirmasiBayar,
        closeModalBayar,
        closeModalKonfirmasiBayar,
        resetCart,
        handleReset,
        setFetchingItems,
        handlingDonePembayaran,
        checked,
        setChecked,
        // keteranganBeli,
        handleIsKeterangan,
        // resetKeteranganBeli,
        checkedCustomAmount,
        checkedOngkir,
        hargaCustomAmount,
        keteranganCustomAmount,
        hargaOngkir,
        keteranganOngkir,
    } = useTransaksi();

    const noInvoice = useMemo(
        () => `U-${moment().format("YYMMDDHHmmss")}`,
        [isOpenModalKonfirmasiBayar],
    );

    const noInvoicePiutang = useMemo(
        () => `INV-${moment().format("YYMMDDHHmmss")}`,
        [isOpenModalKonfirmasiBayar],
    );

    const pilihTunai = () => {
        setToggleModePembayaran(ModePembayaran.TUNAI);
    };

    const pilihTransfer = () => {
        setToggleModePembayaran(ModePembayaran.TRANSFER);
    };

    const pilihUtang = () => {
        setToggleModePembayaran(ModePembayaran.UTANG);
    };

    const pilihPO = () => {
        setToggleModePembayaran(ModePembayaran.PO);
    };

    const current = new Date();
    current.getSeconds();

    const handleHitungTotalHargaSeluruhItem = useCallback(() => {
        let total = 0;
        if (cart) {
            cart.forEach((list) => {
                total += list.harga * list.qty ?? 0;
            });
            setTotalHargaSeluruhItem(total);
            return;
        }
    }, [cart]);

    useEffect(() => {
        handleHitungTotalHargaSeluruhItem();
    }, [cart, handleHitungTotalHargaSeluruhItem]);

    const handlePrintStruk = (checked: boolean) => {
        if (checked) {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    };

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("printStruk") === "true") {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    }, []);

    const filterNewKodeFaktur = useCallback(
        (idBundling: number) => {
            let kodeFakturBundling: IFaktur[] = [];
            cart.forEach((li) =>
                li.detailBundling
                    .filter((fd) => fd.bundlingId === idBundling)
                    .forEach((db) => {
                        if (db.newFaktur) {
                            db.newFaktur.forEach((nf) => {
                                kodeFakturBundling.push(nf);
                            });
                        }
                    }),
            );
            return kodeFakturBundling.map((li) => ({
                idFaktur: li.fakturId,
                kodeFaktur: li.kodeFaktur,
                jumlahItem: li.jumlahItem,
            }));
        },
        [cart],
    );

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    closeModalKonfirmasiBayar();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.data}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalBayar();
                } else {
                    closeModalKonfirmasiBayar();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalBayar();
                }
            }
        },
        [closeModalBayar, closeModalKonfirmasiBayar],
    );

    const initialValues: ModalUtangField = useMemo(
        () => ({
            isPelangganBaru: false,
            nama: "",
            alamat: "",
            noTelepon: "",
            jatuhTempo: null,
        }),
        [],
    );

    const { handleSubmit, control, reset, watch } = useForm<ModalUtangField>({
        defaultValues: initialValues,
    });

    const watchPelanggan = watch("pelanggan");
    const watchIsPelangganBaru = watch("isPelangganBaru");
    const watchJatuhTempo = watch("jatuhTempo");
    useEffect(
        () => console.log("hargaCustomAmount", hargaCustomAmount),
        [hargaCustomAmount],
    );
    useEffect(() => console.log("hargaOngkir", hargaOngkir), [hargaOngkir]);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const onSubmit = async (values: ModalUtangField) => {
        setIsButtonLoading(true);
        const body = {
            ukmId: ukmIdUser && ukmIdUser[0],
            noInvoice:
                toggleModePembayaran === ModePembayaran.UTANG
                    ? noInvoicePiutang
                    : noInvoice,
            jenisItem: cart.map((li) => ({
                itemId: li.id,
                namaItem: li.namaBarang,
                skuItem: li.sku,
                jumlahItem: li.qty,
                hargaSatuan: li.harga,
                hargaTotal: li.harga * li.qty,
                kodeFaktur: checked
                    ? li.detailBundling.length > 0
                        ? filterNewKodeFaktur(li.id)
                        : li.faktur.map((fa) => ({
                              idFaktur: fa.fakturId,
                              kodeFaktur: fa.kodeFaktur,
                              jumlahItem: fa.jumlahItem,
                          }))
                    : null,
            })),
            userInput: `${user?.userId}`,
            sales: !!props.sales ? props.sales.id : null,
            totalHarga: totalHargaSeluruhItem,
            diskon: props.diskon,
            pajak: props.pajak,
            totalPembayaran: props.totalTagihan,
            uangYangDibayar:
                toggleModePembayaran === ModePembayaran.UTANG
                    ? 0
                    : toggleModePembayaran === ModePembayaran.TRANSFER
                    ? props.totalTagihan
                    : props.uangDibayar,
            kembalian:
                toggleModePembayaran === ModePembayaran.UTANG ||
                toggleModePembayaran === ModePembayaran.TRANSFER
                    ? 0
                    : props.kembalian,
            metodeId:
                toggleModePembayaran === ModePembayaran.TUNAI ||
                toggleModePembayaran === ModePembayaran.PO ||
                toggleModePembayaran === ModePembayaran.UTANG
                    ? 0
                    : selectedMetode?.id,
            email: toggleKirimStruk === "email" ? email : null,
            keterangan: !!props.keteranganBeli ? props.keteranganBeli : null,
            customer: values.isPelangganBaru
                ? undefined
                : values.pelanggan
                ? values.pelanggan.id
                : null,
            customerBaru: values.isPelangganBaru
                ? {
                      nama: values.nama,
                      alamat: values.alamat,
                      noTelepon: values.noTelepon,
                      coa: values.coa ? values.coa.id : null,
                  }
                : undefined,
            jatuhTempo: values.jatuhTempo
                ? moment(values.jatuhTempo).format("DD/MM/YYYY")
                : undefined,
            ongkir:
                checkedOngkir && hargaOngkir
                    ? Number(formatHarga(hargaOngkir))
                    : undefined,
            ketOngkir:
                checkedOngkir && keteranganOngkir
                    ? keteranganOngkir
                    : undefined,
            customAmount:
                checkedCustomAmount && hargaCustomAmount
                    ? Number(formatHarga(hargaCustomAmount))
                    : undefined,
            ketCustomAmount:
                checkedCustomAmount && keteranganCustomAmount
                    ? keteranganCustomAmount
                    : undefined,
            metodePelanggan: metodePelanggan ? metodePelanggan : undefined,
        };
        console.log("body", body);
        try {
            if (body.totalPembayaran <= 0) {
                Swal.fire({
                    title: "Total Tagihan tidak boleh <= 0",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            } else {
                if (toggleModePembayaran === ModePembayaran.PO) {
                    const { data } = await axios.post<InputPembayaranResponse>(
                        "/api/transaksipo",
                        body,
                    );
                    if (data.code === 200) {
                        if (kirimEmail && toggleKirimStruk === "whatsapp") {
                            window.open(
                                `https://wa.me/send?phone=62${email}&text=Struk%20pembayaran%20Aplikasi%20BUMDes%20${
                                    data.data.nomorInvoice
                                }%20${
                                    process.env.NODE_ENV === "production"
                                        ? `https://bumdes-penjualan.noretest2.com/`
                                        : `http://localhost:3001`
                                }/struk/${data.data.encrypt}`,
                                "_blank",
                            );
                        }
                        props.refetch && props.refetch();
                        refetchItem();
                        closeModalKonfirmasiBayar();
                        Swal.fire({
                            title: "Berhasil!",
                            text: "Barang berhasil di pre order",
                            icon: "success",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        if (printStruk) {
                            localStorage.setItem("printStruk", "true");
                        } else {
                            localStorage.removeItem("printStruk");
                        }
                        closeModalBayar();
                        setFetchingItems();
                        resetCart();
                        handleReset();
                        setChecked(false);
                        handleIsKeterangan(false);
                        props.resetKeteranganBeli();
                        handlingDonePembayaran();
                    }
                } else {
                    const { data } = await axios.post<InputPembayaranResponse>(
                        "/api/pembayaran",
                        body,
                    );
                    if (data.code === 200) {
                        if (kirimEmail && toggleKirimStruk === "whatsapp") {
                            window.open(
                                `https://wa.me/send?phone=62${email}&text=Struk%20pembayaran%20Aplikasi%20BUMDes%20${
                                    data.data.nomorInvoice
                                }%20${
                                    process.env.NODE_ENV === "production"
                                        ? `https://bumdes-penjualan.noretest2.com/`
                                        : `http://localhost:3001`
                                }/struk/${data.data.encrypt}`,
                                "_blank",
                            );
                        }
                        props.refetch && props.refetch();
                        refetchItem();
                        closeModalKonfirmasiBayar();
                        Swal.fire({
                            title: "Transaksi berhasil!",
                            text: "Pembayaran Anda telah berhasil",
                            icon: "success",
                            confirmButtonColor: "#45A779",
                            customClass: {
                                container: "my-swal",
                            },
                        });
                        if (printStruk) {
                            localStorage.setItem("printStruk", "true");
                        } else {
                            localStorage.removeItem("printStruk");
                        }
                        closeModalBayar();
                        setFetchingItems();
                        resetCart();
                        handleReset();
                        setChecked(false);
                        handleIsKeterangan(false);
                        props.resetKeteranganBeli();
                        handlingDonePembayaran();
                    }
                }
            }
            setIsButtonLoading(false);
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    useEffect(() => {
        if (isOpenModalKonfirmasiBayar) {
            reset(initialValues);
        }
    }, [initialValues, isOpenModalKonfirmasiBayar, reset]);

    useEffect(() => {
        if (props.isNonTunai) {
            setToggleModePembayaran(ModePembayaran.TRANSFER);
        } else {
            setToggleModePembayaran(ModePembayaran.TUNAI);
        }
    }, [props.isNonTunai]);

    useEffect(() => {
        if (toggleModePembayaran !== ModePembayaran.UTANG) {
            reset();
        }
    }, [reset, toggleModePembayaran]);

    useEffect(() => {
        if (toggleModePembayaran !== ModePembayaran.TRANSFER) {
            setMetodePelanggan("");
        }
    }, [toggleModePembayaran]);

    // useEffect(() => {
    //     if (isPrintStruk) {
    //         inputPembayaran();
    //         resetPrint();
    //     }
    // }, [inputPembayaran, isPrintStruk, resetPrint]);

    // if (status === "loading") {
    //     return (
    //         <Box position="relative">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    return (
        <Box position="relative">
            <Dialog
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen || isTabletScreen}
                open={isOpenModalKonfirmasiBayar}
                onClose={closeModalKonfirmasiBayar}
                scroll="paper"
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                        height: {
                            xs: "100vh",
                            md: isTabletScreen ? "100vh" : "90vh",
                        },
                        paddingBottom: fullScreen ? "350px" : undefined,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton onClick={closeModalKonfirmasiBayar}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Konfirmasi Pembayaran
                        </Typography>
                    </Stack>
                </DialogTitle>
                {/* <Divider flexItem /> */}
                <Stack direction={{ xs: "column-reverse", md: "row" }}>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        paddingX={3}
                        paddingBottom={20}
                        height={
                            fullScreen
                                ? undefined
                                : isTabletScreen
                                ? "calc(100vh - 72px)"
                                : "calc(90vh - 72px)"
                        }
                        sx={{ overflowY: fullScreen ? undefined : "auto" }}
                    >
                        <Box
                            padding={2}
                            border={1}
                            borderColor="#d4d4d4"
                            borderRadius={2}
                            marginBottom={2}
                        >
                            <Typography
                                variant="subtitle1"
                                color="#000000"
                                fontWeight="bold"
                                marginBottom={2}
                            >
                                Metode Pembayaran
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid
                                    item
                                    xs={12}
                                    md={3}
                                    display={
                                        props.isNonTunai ? "none" : undefined
                                    }
                                >
                                    <Button
                                        fullWidth
                                        variant={
                                            toggleModePembayaran ===
                                            ModePembayaran.TUNAI
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={pilihTunai}
                                        sx={{
                                            borderRadius: 4,
                                        }}
                                    >
                                        <Stack alignItems="center">
                                            <IconContext.Provider
                                                value={{
                                                    size: isTabletScreen
                                                        ? "48px"
                                                        : "56px",
                                                }}
                                            >
                                                <GiMoneyStack />
                                            </IconContext.Provider>
                                            <Typography
                                                fontSize={
                                                    isTabletScreen ? 10 : 12
                                                }
                                            >
                                                Tunai
                                            </Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={props.isNonTunai ? 4 : 3}
                                >
                                    <Stack
                                        component={
                                            ukmData?.isPro === 0 ? Link : Stack
                                        }
                                        underline="none"
                                        onClick={() => {
                                            if (ukmData?.isPro === 1) {
                                                return;
                                            } else {
                                                setIsOpenModalAlertPro(true);
                                            }
                                        }}
                                        sx={{
                                            cursor:
                                                ukmData?.isPro === 0
                                                    ? "pointer"
                                                    : undefined,
                                        }}
                                    >
                                        <Button
                                            // disabled={
                                            //     ukmData?.isPro !== 1 ||
                                            //     metodePembayaran?.length === 0
                                            // }
                                            fullWidth
                                            variant={
                                                toggleModePembayaran ===
                                                ModePembayaran.TRANSFER
                                                    ? "contained"
                                                    : "outlined"
                                            }
                                            onClick={() => {
                                                if (ukmData?.isPro === 1) {
                                                    pilihTransfer();
                                                }
                                            }}
                                            sx={{ borderRadius: 4 }}
                                        >
                                            <Stack alignItems="center">
                                                <IconContext.Provider
                                                    value={{
                                                        size: isTabletScreen
                                                            ? "48px"
                                                            : "56px",
                                                    }}
                                                >
                                                    <GiWallet />
                                                </IconContext.Provider>
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                    gap={1}
                                                >
                                                    <Typography
                                                        fontSize={
                                                            isTabletScreen
                                                                ? 10
                                                                : 12
                                                        }
                                                    >
                                                        Metode Lain
                                                    </Typography>
                                                    {ukmData?.isPro ===
                                                    1 ? null : (
                                                        <Chip
                                                            label="PRO"
                                                            size="small"
                                                        />
                                                    )}
                                                </Box>
                                            </Stack>
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={props.isNonTunai ? 4 : 3}
                                >
                                    <Button
                                        fullWidth
                                        variant={
                                            toggleModePembayaran ===
                                            ModePembayaran.UTANG
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={pilihUtang}
                                        sx={{ borderRadius: 4 }}
                                    >
                                        <Stack alignItems="center">
                                            {/* <IconContext.Provider
                                                value={{
                                                    size: "56px",
                                                }}
                                            >
                                                <GiMoneyStack />
                                            </IconContext.Provider> */}
                                            <ReceiptIcon
                                                sx={{
                                                    width: iconSize,
                                                    height: iconSize,
                                                }}
                                            />
                                            <Typography
                                                fontSize={
                                                    isTabletScreen ? 10 : 12
                                                }
                                            >
                                                Utang
                                            </Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={props.isNonTunai ? 4 : 3}
                                >
                                    <Button
                                        fullWidth
                                        variant={
                                            toggleModePembayaran ===
                                            ModePembayaran.PO
                                                ? "contained"
                                                : "outlined"
                                        }
                                        onClick={pilihPO}
                                        sx={{ borderRadius: 4 }}
                                    >
                                        <Stack alignItems="center">
                                            {/* <IconContext.Provider
                                                value={{
                                                    size: "48px",
                                                }}
                                            >
                                                <GiMoneyStack />
                                            </IconContext.Provider> */}
                                            <BallotIcon
                                                sx={{
                                                    width: iconSize,
                                                    height: iconSize,
                                                }}
                                            />
                                            <Typography
                                                fontSize={
                                                    isTabletScreen ? 10 : 12
                                                }
                                            >
                                                Pre Order
                                            </Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {toggleModePembayaran === ModePembayaran.TRANSFER &&
                        mappedMetodePembayaran?.length !== 0 ? (
                            <Box display="flex" flexDirection="column" gap={2}>
                                <TextField
                                    value={metodePelanggan}
                                    size="small"
                                    fullWidth
                                    label="Nama Pelanggan (opsional)"
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) => setMetodePelanggan(event.target.value)}
                                />
                                <Autocomplete
                                    loading={isLoadingMetodePembayaran}
                                    options={mappedMetodePembayaran ?? []}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Pilih metode"
                                            variant="outlined"
                                        />
                                    )}
                                    getOptionLabel={(option) => option.nama}
                                    isOptionEqualToValue={(option, value) =>
                                        String(option.id) === String(value.id)
                                    }
                                    size="small"
                                    fullWidth
                                    value={selectedMetode}
                                    onChange={(
                                        _event,
                                        newKategori: IOptions | null,
                                    ) => {
                                        setSelectedMetode(newKategori);
                                    }}
                                    sx={{ marginBottom: 2 }}
                                />
                                {findMetodePembayaran && (
                                    <Stack alignItems="center">
                                        {findMetodePembayaran.gambar && (
                                            <img
                                                src={
                                                    findMetodePembayaran.gambar
                                                }
                                                alt="qrcode"
                                                width={250}
                                                height={250}
                                            />
                                        )}
                                        <Typography>
                                            a/n{" "}
                                            {findMetodePembayaran.namaPemegang}
                                        </Typography>
                                        <Typography fontWeight="bold">
                                            {findMetodePembayaran.noTujuan}
                                        </Typography>
                                        <Stack
                                            marginTop={2}
                                            direction="row"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography>
                                                Pilih tipe struk
                                            </Typography>
                                            <ToggleButtonGroup
                                                value={tipeStrukTagihan}
                                                exclusive
                                                size="small"
                                                onChange={
                                                    handleTipeStrukTagihan
                                                }
                                            >
                                                <ToggleButton
                                                    value={TipeStruk.Mobile}
                                                    sx={{
                                                        display: "flex",
                                                        paddingX: 2,
                                                    }}
                                                >
                                                    <b>A8</b>
                                                </ToggleButton>
                                                <ToggleButton
                                                    value={TipeStruk.PC}
                                                    sx={{
                                                        display: "flex",
                                                        paddingX: 2,
                                                    }}
                                                >
                                                    <b>A4</b>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Stack>
                                        {ukmData &&
                                            (tipeStrukTagihan ===
                                            TipeStruk.Mobile ? (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukPdf
                                                            namaToko={String(
                                                                ukmData?.namaToko,
                                                            )}
                                                            alamatToko={String(
                                                                ukmData?.alamatToko,
                                                            )}
                                                            gambarToko={
                                                                ukmData?.gambarTokoBW ===
                                                                null
                                                                    ? undefined
                                                                    : ukmData?.gambarTokoBW
                                                            }
                                                            telpToko={String(
                                                                ukmData?.noTelp,
                                                            )}
                                                            tanggalTransaksi={
                                                                now
                                                            }
                                                            kasir={props.kasir}
                                                            sales={
                                                                props.sales
                                                                    ?.nama ===
                                                                undefined
                                                                    ? null
                                                                    : props
                                                                          .sales
                                                                          .nama
                                                            }
                                                            total={
                                                                props.totalTagihan
                                                            }
                                                            cart={cart}
                                                            diskon={
                                                                props.diskon
                                                            }
                                                            pajak={props.pajak}
                                                            belumLunas={true}
                                                            qr={
                                                                findMetodePembayaran.gambar
                                                            }
                                                            isPro={Number(
                                                                ukmData?.isPro,
                                                            )}
                                                            namaMetode={
                                                                selectedMetode &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? selectedMetode.nama
                                                                    : "Tunai"
                                                            }
                                                            html={
                                                                findCustomStruk &&
                                                                findCustomStruk.html !==
                                                                    null
                                                                    ? findCustomStruk?.html
                                                                    : undefined
                                                            }
                                                            keterangan={
                                                                props.keteranganBeli
                                                            }
                                                            subTotal={
                                                                props.subTotal
                                                            }
                                                            noInvoice={
                                                                noInvoice
                                                            }
                                                            keteranganCustomAmount={
                                                                checkedCustomAmount
                                                                    ? keteranganCustomAmount
                                                                    : undefined
                                                            }
                                                            hargaCustomAmount={
                                                                checkedCustomAmount
                                                                    ? hargaCustomAmount?.replace(
                                                                          /\./g,
                                                                          "",
                                                                      )
                                                                    : undefined
                                                            }
                                                            keteranganOngkir={
                                                                checkedOngkir
                                                                    ? keteranganOngkir
                                                                    : undefined
                                                            }
                                                            hargaOngkir={
                                                                checkedOngkir
                                                                    ? hargaOngkir?.replace(
                                                                          /\./g,
                                                                          "",
                                                                      )
                                                                    : undefined
                                                            }
                                                            jatuhTempo={null}
                                                            metodePelanggan={
                                                                metodePelanggan
                                                                    ? metodePelanggan
                                                                    : ""
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                variant="outlined"
                                                                loading={
                                                                    loading
                                                                }
                                                                loadingPosition="center"
                                                                startIcon={
                                                                    <Print />
                                                                }
                                                                href={url ?? ""}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                sx={{
                                                                    marginTop: 2,
                                                                }}
                                                            >
                                                                Cetak Tagihan
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            ) : (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukA4
                                                            namaToko={String(
                                                                ukmData?.namaToko,
                                                            )}
                                                            alamatToko={String(
                                                                ukmData?.alamatToko,
                                                            )}
                                                            gambarToko={
                                                                ukmData?.gambarTokoBW ===
                                                                null
                                                                    ? undefined
                                                                    : ukmData?.gambarTokoBW
                                                            }
                                                            telpToko={String(
                                                                ukmData?.noTelp,
                                                            )}
                                                            tanggalTransaksi={
                                                                now
                                                            }
                                                            kasir={props.kasir}
                                                            sales={
                                                                props.sales
                                                                    ?.nama ===
                                                                undefined
                                                                    ? null
                                                                    : props
                                                                          .sales
                                                                          .nama
                                                            }
                                                            total={
                                                                props.totalTagihan
                                                            }
                                                            cart={cart}
                                                            diskon={
                                                                props.diskon
                                                            }
                                                            pajak={props.pajak}
                                                            belumLunas={true}
                                                            qr={
                                                                findMetodePembayaran.gambar
                                                            }
                                                            isPro={Number(
                                                                ukmData?.isPro,
                                                            )}
                                                            namaMetode={
                                                                selectedMetode &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? selectedMetode.nama
                                                                    : "Tunai"
                                                            }
                                                            html={
                                                                findCustomStruk &&
                                                                findCustomStruk.html !==
                                                                    null
                                                                    ? findCustomStruk?.html
                                                                    : undefined
                                                            }
                                                            keterangan={
                                                                props.keteranganBeli
                                                            }
                                                            subTotal={
                                                                props.subTotal
                                                            }
                                                            noInvoice={
                                                                noInvoice
                                                            }
                                                            keteranganCustomAmount={
                                                                checkedCustomAmount
                                                                    ? keteranganCustomAmount
                                                                    : undefined
                                                            }
                                                            hargaCustomAmount={
                                                                checkedCustomAmount
                                                                    ? hargaCustomAmount?.replace(
                                                                          /\./g,
                                                                          "",
                                                                      )
                                                                    : undefined
                                                            }
                                                            keteranganOngkir={
                                                                checkedOngkir
                                                                    ? keteranganOngkir
                                                                    : undefined
                                                            }
                                                            hargaOngkir={
                                                                checkedOngkir
                                                                    ? hargaOngkir?.replace(
                                                                          /\./g,
                                                                          "",
                                                                      )
                                                                    : undefined
                                                            }
                                                            jatuhTempo={null}
                                                            metodePelanggan={
                                                                metodePelanggan
                                                                    ? metodePelanggan
                                                                    : ""
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                variant="outlined"
                                                                loading={
                                                                    loading
                                                                }
                                                                loadingPosition="center"
                                                                startIcon={
                                                                    <Print />
                                                                }
                                                                href={url ?? ""}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                sx={{
                                                                    marginTop: 2,
                                                                }}
                                                            >
                                                                Cetak Tagihan
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            ))}
                                    </Stack>
                                )}
                            </Box>
                        ) : (
                            toggleModePembayaran === ModePembayaran.TRANSFER &&
                            mappedMetodePembayaran?.length === 0 && (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                    height="50%"
                                    paddingX={isPhoneScreen ? undefined : 2}
                                >
                                    <Typography textAlign="center">
                                        Tambahkan Bank Transfer, e-Money, QRIS,
                                        dan lain-lain sebagai metode alternatif
                                        transaksi BUMDes Anda.
                                    </Typography>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        marginTop={4}
                                    >
                                        <Typography
                                            variant="body2"
                                            textAlign="center"
                                        >
                                            Metode lain dapat diatur di
                                            bumdes-manager.noretest2.com
                                        </Typography>
                                    </Box>
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        marginTop={2}
                                    >
                                        <Button
                                            variant="outlined"
                                            href={
                                                process.env.NODE_ENV ===
                                                "production"
                                                    ? `https://bumdes-manager.noretest2.com/penjualan/metode-pembayaran`
                                                    : `http://localhost:3000/penjualan/metode-pembayaran`
                                            }
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Ke Manager
                                        </Button>
                                    </Box>
                                </Box>
                            )
                        )}
                        {toggleModePembayaran === ModePembayaran.UTANG && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Controller
                                        name="isPelangganBaru"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        size={
                                                            isTabletScreen
                                                                ? "small"
                                                                : undefined
                                                        }
                                                        {...field}
                                                    />
                                                }
                                                label="Pelanggan baru"
                                                componentsProps={{
                                                    typography: {
                                                        variant: "body2",
                                                        fontSize: isTabletScreen
                                                            ? "12px"
                                                            : undefined,
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: watchIsPelangganBaru
                                            ? "none"
                                            : undefined,
                                    }}
                                >
                                    <Typography>Pilih pelanggan</Typography>
                                    <Controller
                                        name="pelanggan"
                                        control={control}
                                        rules={{
                                            required: watchIsPelangganBaru
                                                ? undefined
                                                : "Kolom wajib diisi",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                loading={isLoadingPelanggan}
                                                options={mappedPelanggan ?? []}
                                                getOptionLabel={(option) =>
                                                    option.nama
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                size="small"
                                                value={field.value}
                                                onInputChange={(e) => {
                                                    if (e) {
                                                        const element =
                                                            e.target as HTMLInputElement;
                                                        setParamsPelanggan(
                                                            (prev) => ({
                                                                ...prev,
                                                                search: element.value,
                                                            }),
                                                        );
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                                onChange={(
                                                    _event,
                                                    newPelanggan: IOptions | null,
                                                ) => {
                                                    field.onChange(
                                                        newPelanggan,
                                                    );
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Pilih pelanggan"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error &&
                                                            "Pilih satu pelanggan"
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: watchIsPelangganBaru
                                            ? undefined
                                            : "none",
                                    }}
                                >
                                    <Typography>Pelanggan baru</Typography>
                                    <Controller
                                        name="nama"
                                        control={control}
                                        rules={{
                                            required: watchIsPelangganBaru
                                                ? "Kolom wajib diisi"
                                                : undefined,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Nama"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error
                                                        ? fieldState.error
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: watchIsPelangganBaru
                                            ? undefined
                                            : "none",
                                    }}
                                >
                                    <Controller
                                        name="coa"
                                        control={control}
                                        rules={{
                                            required: watchIsPelangganBaru
                                                ? "Kolom wajib diisi"
                                                : undefined,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Autocomplete
                                                disablePortal
                                                disableClearable
                                                loading={isLoadingCoa}
                                                options={mappedCoa ?? []}
                                                getOptionLabel={(option) => {
                                                    return `(${option.noAkun}) - ${option.namaAkun}`;
                                                }}
                                                isOptionEqualToValue={(
                                                    option,
                                                    value,
                                                ) => option.id === value.id}
                                                size="small"
                                                value={field.value}
                                                onInputChange={(e) => {
                                                    if (e) {
                                                        const element =
                                                            e.target as HTMLInputElement;
                                                        setParamsCoa(
                                                            (prev) => ({
                                                                ...prev,
                                                                search: element.value,
                                                            }),
                                                        );
                                                    } else {
                                                        return;
                                                    }
                                                }}
                                                onChange={(
                                                    _event,
                                                    newCoa: {
                                                        id: number;
                                                        noAkun: string;
                                                        namaAkun: string;
                                                    } | null,
                                                ) => {
                                                    field.onChange(newCoa);
                                                }}
                                                fullWidth
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Pilih COA"
                                                        error={
                                                            !!fieldState?.error
                                                        }
                                                        helperText={
                                                            fieldState?.error &&
                                                            "Pilih satu COA"
                                                        }
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{
                                        display: watchIsPelangganBaru
                                            ? undefined
                                            : "none",
                                    }}
                                >
                                    <Controller
                                        name="noTelepon"
                                        control={control}
                                        rules={{
                                            required: watchIsPelangganBaru
                                                ? "Kolom wajib diisi"
                                                : undefined,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="No Telepon"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error
                                                        ? fieldState.error
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: watchIsPelangganBaru
                                            ? undefined
                                            : "none",
                                    }}
                                >
                                    <Controller
                                        name="alamat"
                                        control={control}
                                        rules={{
                                            required: watchIsPelangganBaru
                                                ? "Kolom wajib diisi"
                                                : undefined,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Alamat"
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                multiline
                                                rows={2}
                                                error={!!fieldState.error}
                                                helperText={
                                                    fieldState.error
                                                        ? fieldState.error
                                                              .message
                                                        : ""
                                                }
                                                {...field}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>Jatuh tempo</Typography>
                                    <Controller
                                        name="jatuhTempo"
                                        control={control}
                                        rules={{
                                            required: "Kolom wajib diisi",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <DatePicker
                                                className="range-filter"
                                                dateFormat="dd/MM/yyyy"
                                                disabledKeyboardNavigation
                                                onFocus={(e) => e.target.blur()}
                                                selected={field.value}
                                                onChange={(update) => {
                                                    field.onChange(update);
                                                }}
                                                // minDate={now}
                                                monthsShown={1}
                                                customInput={
                                                    <FormControl fullWidth>
                                                        <Stack
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            direction="row"
                                                            // width="100%"
                                                            bgcolor="#FFFF"
                                                            height="40px"
                                                            sx={{
                                                                border: !!fieldState.error
                                                                    ? "1px solid red"
                                                                    : "1px solid #cbcbcb",
                                                                borderRadius:
                                                                    "4px",
                                                                alignItems:
                                                                    "center",
                                                                padding: 2,
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            <Typography
                                                                color={
                                                                    field.value
                                                                        ? undefined
                                                                        : "#cbcbcb"
                                                                }
                                                            >
                                                                {field.value
                                                                    ? field.value?.toLocaleString(
                                                                          "id",
                                                                          {
                                                                              year: "numeric",
                                                                              month: "long",
                                                                              day: "numeric",
                                                                          },
                                                                      )
                                                                    : "Pilih jatuh tempo"}
                                                            </Typography>
                                                            <ArrowDropDown
                                                                sx={{
                                                                    marginRight:
                                                                        "-9px",
                                                                }}
                                                            />
                                                        </Stack>
                                                        <FormHelperText
                                                            error={
                                                                !!fieldState.error
                                                            }
                                                        >
                                                            {fieldState.error
                                                                ? fieldState
                                                                      .error
                                                                      ?.message
                                                                : ""}
                                                        </FormHelperText>
                                                    </FormControl>
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        lg={6}
                        borderLeft="1px solid #d4d4d4"
                        borderRadius="10px"
                    >
                        {/* <Divider flexItem /> */}
                        <Box
                            display="grid"
                            gridTemplateRows="auto 1fr auto"
                            height={
                                fullScreen
                                    ? undefined
                                    : isTabletScreen
                                    ? "calc(100vh - 72px)"
                                    : "calc(90vh - 72px)"
                            }
                        >
                            <Stack
                                direction="row"
                                paddingLeft={3}
                                paddingRight={4}
                                paddingY={2}
                                borderBottom="1px solid #d4d4d4"
                                borderTop="1px solid #d4d4d4"
                                borderRadius="10px 0 0 0"
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Typography
                                            variant="subtitle2"
                                            color="#A1A1AA"
                                        >
                                            Tanggal
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color="black"
                                        >
                                            {now}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            variant="subtitle2"
                                            color="#A1A1AA"
                                        >
                                            Nama Kasir
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            color="black"
                                        >
                                            {user?.namaLengkap}
                                        </Typography>
                                    </Grid>
                                    {!!props.sales && (
                                        <Grid item>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                Nama Sales
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="black"
                                            >
                                                {props.sales.nama}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Stack>
                            <DialogContent
                                sx={{
                                    paddingBottom: 2,
                                }}
                            >
                                <Stack direction="column" spacing={1}>
                                    {cart ? (
                                        cart.map((rows, idx) => (
                                            <Box key={String(idx)}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    <Box width="50%">
                                                        <Box>
                                                            <Typography
                                                                variant={
                                                                    isTabletScreen
                                                                        ? "body2"
                                                                        : "subtitle1"
                                                                }
                                                                fontWeight={600}
                                                                color="black"
                                                            >
                                                                {
                                                                    rows.namaBarang
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant={
                                                                    isTabletScreen
                                                                        ? "body2"
                                                                        : "subtitle1"
                                                                }
                                                            >
                                                                Rp.{" "}
                                                                {toRibuan(
                                                                    rows.harga,
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box display="flex">
                                                        <Typography
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight={600}
                                                            color="black"
                                                        >
                                                            {rows.qty}x
                                                        </Typography>
                                                    </Box>
                                                    <Stack
                                                        direction="row"
                                                        width="30%"
                                                        display="flex"
                                                        justifyContent="flex-end"
                                                    >
                                                        <Typography
                                                            display="flex"
                                                            variant={
                                                                isTabletScreen
                                                                    ? "body2"
                                                                    : "subtitle1"
                                                            }
                                                            fontWeight={600}
                                                            color="black"
                                                            textAlign="end"
                                                        >
                                                            Rp.{" "}
                                                            {toRibuan(
                                                                rows.harga *
                                                                    rows.qty,
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="h6">
                                            Barang tidak ditemukan
                                        </Typography>
                                    )}
                                    {checkedCustomAmount && (
                                        <Box>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                            >
                                                <Box width="50%">
                                                    <Box>
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight={600}
                                                            color="black"
                                                        >
                                                            Custom Amount
                                                        </Typography>
                                                        <Typography>
                                                            {
                                                                keteranganCustomAmount
                                                            }
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Stack
                                                    direction="row"
                                                    width="30%"
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                >
                                                    <Typography
                                                        display="flex"
                                                        variant="subtitle1"
                                                        fontWeight={600}
                                                        color="black"
                                                        textAlign="end"
                                                    >
                                                        Rp. {hargaCustomAmount}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    )}
                                    {checkedOngkir && (
                                        <Box>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                            >
                                                <Box width="50%">
                                                    <Box>
                                                        <Typography
                                                            variant="subtitle1"
                                                            fontWeight={600}
                                                            color="black"
                                                        >
                                                            Ongkos Kirim
                                                        </Typography>
                                                        <Typography>
                                                            {keteranganOngkir}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Stack
                                                    direction="row"
                                                    width="30%"
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                >
                                                    <Typography
                                                        display="flex"
                                                        variant="subtitle1"
                                                        fontWeight={600}
                                                        color="black"
                                                        textAlign="end"
                                                    >
                                                        Rp. {hargaOngkir}
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                    )}
                                </Stack>
                                <Box
                                    marginY={2}
                                    padding={2}
                                    border={1}
                                    borderColor="#d4d4d4"
                                    borderRadius={2}
                                >
                                    <Typography
                                        marginBottom={1}
                                        variant={
                                            isTabletScreen
                                                ? "body2"
                                                : "subtitle1"
                                        }
                                        color="#000000"
                                        fontWeight="bold"
                                    >
                                        Ringkasan Pembayaran
                                    </Typography>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6} sm={3} md={4} lg={4}>
                                            <Typography
                                                variant={
                                                    isTabletScreen
                                                        ? "body2"
                                                        : "subtitle1"
                                                }
                                                color="#404040"
                                            >
                                                Subtotal Harga
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={5}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    fontWeight="bold"
                                                >
                                                    {toRibuan(totalHarga)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {checkedCustomAmount && (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            marginTop={1}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Custom Amount
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        {hargaCustomAmount}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {checkedOngkir && (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            marginTop={1}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                sm={3}
                                                md={4}
                                                lg={4}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#404040"
                                                >
                                                    Ongkos Kirim
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={3} xs={5}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    paddingX={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        Rp.
                                                    </Typography>
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                    >
                                                        {hargaOngkir}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid
                                        container
                                        marginTop={1}
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6} sm={4} md={5} lg={5}>
                                            <Typography
                                                variant={
                                                    isTabletScreen
                                                        ? "body2"
                                                        : "subtitle1"
                                                }
                                                color="#404040"
                                            >
                                                Diskon{" "}
                                                {props.persentaseDiskon &&
                                                props.persentaseDiskon > 0
                                                    ? `(${props.persentaseDiskon}%)`
                                                    : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={5}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                bgcolor="#FFF5EC"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    {toRibuan(
                                                        props.diskon ?? 0,
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        marginTop={1}
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6} sm={4} md={5} lg={5}>
                                            <Typography
                                                variant={
                                                    isTabletScreen
                                                        ? "body2"
                                                        : "subtitle1"
                                                }
                                                color="#404040"
                                            >
                                                Pajak{" "}
                                                {props.persentasePajak &&
                                                props.persentasePajak > 0
                                                    ? `(${props.persentasePajak}%)`
                                                    : ""}
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={5}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                bgcolor="#FFF5EC"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    {toRibuan(props.pajak ?? 0)}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        marginTop={1}
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6} sm={3} md={4} lg={4}>
                                            <Typography
                                                variant={
                                                    isTabletScreen
                                                        ? "body2"
                                                        : "subtitle1"
                                                }
                                                color="#404040"
                                            >
                                                Total Tagihan
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={5}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                bgcolor="#E8F6ED"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#45A779"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#45A779"
                                                    fontWeight="bold"
                                                >
                                                    {toRibuan(
                                                        props.totalTagihan,
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        marginTop={1}
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6} sm={3} md={4} lg={4}>
                                            <Typography
                                                variant={
                                                    isTabletScreen
                                                        ? "body2"
                                                        : "subtitle1"
                                                }
                                                color="#404040"
                                            >
                                                Uang yang Dibayar
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={5}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                bgcolor="#FFF5EC"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#FB923C"
                                                    fontWeight="bold"
                                                >
                                                    {toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? toRibuan(0)
                                                        : toggleModePembayaran ===
                                                          ModePembayaran.TRANSFER
                                                        ? toRibuan(
                                                              props.totalTagihan,
                                                          )
                                                        : toRibuan(
                                                              props.uangDibayar ??
                                                                  0,
                                                          )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        alignItems="center"
                                        marginTop={1}
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6} sm={3} md={4} lg={4}>
                                            <Typography
                                                variant={
                                                    isTabletScreen
                                                        ? "body2"
                                                        : "subtitle1"
                                                }
                                                color="#404040"
                                            >
                                                Kembalian
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} xs={5}>
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                bgcolor="#FCEAEA"
                                                paddingX={1}
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#DC2626"
                                                    fontWeight="bold"
                                                >
                                                    Rp.
                                                </Typography>
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    color="#DC2626"
                                                    fontWeight="bold"
                                                >
                                                    {toggleModePembayaran ===
                                                        ModePembayaran.UTANG ||
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER
                                                        ? toRibuan(0)
                                                        : toRibuan(
                                                              props.kembalian ??
                                                                  0,
                                                          )}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    {props.keteranganBeli && (
                                        <Grid
                                            container
                                            alignItems="center"
                                            marginTop={1}
                                        >
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="subtitle1"
                                                    color="#404040"
                                                >
                                                    Keterangan
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled
                                                    multiline
                                                    rows={2}
                                                    fullWidth
                                                    value={props.keteranganBeli}
                                                    sx={{
                                                        "& .MuiOutlinedInput-input.Mui-disabled":
                                                            {
                                                                color: "rgba(0, 0, 0, 0.87)",
                                                                WebkitTextFillColor:
                                                                    "rgba(0, 0, 0, 0.87)",
                                                            },
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Box>
                            </DialogContent>
                            <Grid
                                container
                                justifyContent="flex-end"
                                display={fullScreen ? "none" : undefined}
                            >
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <Box
                                        paddingTop={3}
                                        paddingLeft={3}
                                        display="flex"
                                        justifyContent="flex-start"
                                        borderTop={1}
                                        borderColor="#d4d4d4"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={printStruk}
                                                    size={
                                                        isTabletScreen
                                                            ? "small"
                                                            : undefined
                                                    }
                                                    onChange={(_, checked) => {
                                                        handlePrintStruk(
                                                            checked,
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Print struk"
                                            componentsProps={{
                                                typography: {
                                                    fontSize: isTabletScreen
                                                        ? "12px"
                                                        : undefined,
                                                },
                                            }}
                                            sx={{
                                                height: "fit-content",
                                            }}
                                        />
                                        {printStruk && (
                                            <ToggleButtonGroup
                                                value={tipeStruk}
                                                exclusive
                                                size="small"
                                                onChange={handleTipeStruk}
                                            >
                                                <ToggleButton
                                                    value={TipeStruk.Mobile}
                                                    sx={{
                                                        display: "flex",
                                                        paddingX: 2,
                                                    }}
                                                >
                                                    <b>A8</b>
                                                </ToggleButton>
                                                <ToggleButton
                                                    value={TipeStruk.PC}
                                                    sx={{
                                                        display: "flex",
                                                        paddingX: 2,
                                                    }}
                                                >
                                                    <b>A4</b>
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        )}
                                    </Box>
                                    <Box
                                        paddingLeft={3}
                                        display="flex"
                                        // justifyContent="flex-start"
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={kirimEmail}
                                                    size={
                                                        isTabletScreen
                                                            ? "small"
                                                            : undefined
                                                    }
                                                    onChange={(_, checked) => {
                                                        handleKirimEmail(
                                                            checked,
                                                        );
                                                    }}
                                                />
                                            }
                                            label="Kirim struk"
                                            componentsProps={{
                                                typography: {
                                                    fontSize: isTabletScreen
                                                        ? "12px"
                                                        : undefined,
                                                },
                                            }}
                                            sx={{
                                                height: "fit-content",
                                            }}
                                        />
                                    </Box>
                                    {kirimEmail && (
                                        <Box paddingX={3}>
                                            <Grid container>
                                                <Grid item xs={12} sm={6}>
                                                    <Stack
                                                        display="flex"
                                                        direction="row"
                                                        spacing={1}
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                    >
                                                        <Button
                                                            disabled={
                                                                user &&
                                                                !user.akses.transaksi.includes(
                                                                    12,
                                                                )
                                                            }
                                                            size={
                                                                isTabletScreen
                                                                    ? "small"
                                                                    : "medium"
                                                            }
                                                            fullWidth
                                                            variant={
                                                                toggleKirimStruk ===
                                                                "whatsapp"
                                                                    ? "contained"
                                                                    : "outlined"
                                                            }
                                                            onClick={() =>
                                                                pilihWhatsapp()
                                                            }
                                                        >
                                                            Whatsapp
                                                        </Button>
                                                        <Button
                                                            disabled={
                                                                user &&
                                                                !user.akses.transaksi.includes(
                                                                    12,
                                                                )
                                                            }
                                                            size={
                                                                isTabletScreen
                                                                    ? "small"
                                                                    : "medium"
                                                            }
                                                            fullWidth
                                                            variant={
                                                                toggleKirimStruk ===
                                                                "email"
                                                                    ? "contained"
                                                                    : "outlined"
                                                            }
                                                            onClick={() =>
                                                                pilihEmail()
                                                            }
                                                        >
                                                            Email
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        value={email}
                                                        size="small"
                                                        type="email"
                                                        fullWidth
                                                        sx={{ maxWidth: 300 }}
                                                        placeholder={
                                                            toggleKirimStruk ===
                                                            "email"
                                                                ? "Contoh: budi93@gmail.com"
                                                                : "8316352725"
                                                        }
                                                        InputProps={{
                                                            startAdornment:
                                                                toggleKirimStruk ===
                                                                    "whatsapp" && (
                                                                    <InputAdornment position="start">
                                                                        +62
                                                                    </InputAdornment>
                                                                ),
                                                        }}
                                                        onChange={(
                                                            event: React.ChangeEvent<HTMLInputElement>,
                                                        ) =>
                                                            setEmail(
                                                                event.target
                                                                    .value,
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                    <Box
                                        marginTop={isTabletScreen ? 1 : 2}
                                        marginLeft={3}
                                        marginRight={3}
                                        marginBottom={isTabletScreen ? 2 : 4}
                                    >
                                        <Stack
                                            direction="column"
                                            display="flex"
                                            justifyContent="space-between"
                                            marginBottom={1}
                                            spacing={1}
                                        >
                                            <Stack
                                                direction="row"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    fontWeight="bold"
                                                >
                                                    Total
                                                </Typography>
                                                <Box
                                                    bgcolor="#E8F6ED"
                                                    borderRadius={1}
                                                    paddingX={2}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                        color="#45A779"
                                                    >
                                                        Rp.{" "}
                                                        {toRibuan(
                                                            props.totalTagihan,
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <Typography
                                                    variant={
                                                        isTabletScreen
                                                            ? "body2"
                                                            : "subtitle1"
                                                    }
                                                    fontWeight="bold"
                                                >
                                                    Kembalian
                                                </Typography>
                                                <Box
                                                    justifyContent="space-between"
                                                    bgcolor="#FCEAEA"
                                                    paddingX={2}
                                                    borderRadius={1}
                                                >
                                                    <Typography
                                                        variant={
                                                            isTabletScreen
                                                                ? "body2"
                                                                : "subtitle1"
                                                        }
                                                        fontWeight="bold"
                                                        color="#DC2626"
                                                    >
                                                        Rp.{" "}
                                                        {toggleModePembayaran ===
                                                            ModePembayaran.UTANG ||
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER
                                                            ? toRibuan(0)
                                                            : toRibuan(
                                                                  props.kembalian ??
                                                                      0,
                                                              )}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                        {printStruk ? (
                                            tipeStruk === TipeStruk.Mobile ? (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukPdf
                                                            namaToko={String(
                                                                ukmData?.namaToko,
                                                            )}
                                                            alamatToko={String(
                                                                ukmData?.alamatToko,
                                                            )}
                                                            gambarToko={
                                                                ukmData?.gambarTokoBW ===
                                                                null
                                                                    ? undefined
                                                                    : ukmData?.gambarTokoBW
                                                            }
                                                            telpToko={String(
                                                                ukmData?.noTelp,
                                                            )}
                                                            tanggalTransaksi={
                                                                now
                                                            }
                                                            kasir={props.kasir}
                                                            sales={
                                                                props.sales
                                                                    ?.nama ===
                                                                undefined
                                                                    ? null
                                                                    : props
                                                                          .sales
                                                                          .nama
                                                            }
                                                            total={
                                                                props.totalTagihan
                                                            }
                                                            cart={cart}
                                                            diskon={
                                                                props.diskon
                                                            }
                                                            pajak={props.pajak}
                                                            belumLunas={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? true
                                                                    : false
                                                            }
                                                            tunai={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? undefined
                                                                    : Number(
                                                                          props.uangDibayar,
                                                                      )
                                                            }
                                                            kembalian={
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.UTANG ||
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? undefined
                                                                    : Number(
                                                                          props.kembalian,
                                                                      )
                                                            }
                                                            isPro={Number(
                                                                ukmData?.isPro,
                                                            )}
                                                            namaMetode={
                                                                selectedMetode &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? selectedMetode.nama
                                                                    : toggleModePembayaran ===
                                                                      ModePembayaran.UTANG
                                                                    ? "Piutang Usaha"
                                                                    : "Tunai"
                                                            }
                                                            html={
                                                                findCustomStruk &&
                                                                findCustomStruk.html !==
                                                                    null
                                                                    ? findCustomStruk?.html
                                                                    : undefined
                                                            }
                                                            keterangan={
                                                                props.keteranganBeli
                                                            }
                                                            noInvoice={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? noInvoicePiutang
                                                                    : noInvoice
                                                            }
                                                            subTotal={
                                                                props.subTotal
                                                            }
                                                            keteranganCustomAmount={
                                                                checkedCustomAmount
                                                                    ? keteranganCustomAmount
                                                                    : undefined
                                                            }
                                                            hargaCustomAmount={
                                                                checkedCustomAmount
                                                                    ? hargaCustomAmount
                                                                    : undefined
                                                            }
                                                            keteranganOngkir={
                                                                checkedOngkir
                                                                    ? keteranganOngkir
                                                                    : undefined
                                                            }
                                                            hargaOngkir={
                                                                checkedOngkir
                                                                    ? hargaOngkir
                                                                    : undefined
                                                            }
                                                            jatuhTempo={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? moment(
                                                                          watchJatuhTempo,
                                                                      ).format(
                                                                          "DD MMM YYYY",
                                                                      )
                                                                    : null
                                                            }
                                                            metodePelanggan={
                                                                watchPelanggan &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.UTANG
                                                                    ? watchPelanggan.nama
                                                                    : metodePelanggan
                                                                    ? metodePelanggan
                                                                    : ""
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                disabled={
                                                                    toggleModePembayaran ===
                                                                        ModePembayaran.TRANSFER &&
                                                                    selectedMetode ===
                                                                        null
                                                                }
                                                                loading={
                                                                    loading ||
                                                                    isButtonLoading
                                                                }
                                                                loadingPosition="center"
                                                                variant={
                                                                    toggleModePembayaran ===
                                                                    ModePembayaran.PO
                                                                        ? "outlined"
                                                                        : "contained"
                                                                }
                                                                fullWidth
                                                                size={
                                                                    isTabletScreen
                                                                        ? "medium"
                                                                        : "large"
                                                                }
                                                                href={
                                                                    props.totalTagihan >
                                                                    0
                                                                        ? url
                                                                            ? url
                                                                            : ""
                                                                        : ""
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => {
                                                                    handleSubmit(
                                                                        onSubmit,
                                                                    )();
                                                                }}
                                                            >
                                                                {toggleModePembayaran ===
                                                                ModePembayaran.PO
                                                                    ? "Pre Order"
                                                                    : "Bayar"}
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            ) : (
                                                <BlobProvider
                                                    document={
                                                        <PrintStrukA4
                                                            namaToko={String(
                                                                ukmData?.namaToko,
                                                            )}
                                                            alamatToko={String(
                                                                ukmData?.alamatToko,
                                                            )}
                                                            gambarToko={
                                                                ukmData?.gambarTokoBW ===
                                                                null
                                                                    ? undefined
                                                                    : ukmData?.gambarTokoBW
                                                            }
                                                            telpToko={String(
                                                                ukmData?.noTelp,
                                                            )}
                                                            tanggalTransaksi={
                                                                now
                                                            }
                                                            kasir={props.kasir}
                                                            sales={
                                                                props.sales
                                                                    ?.nama ===
                                                                undefined
                                                                    ? null
                                                                    : props
                                                                          .sales
                                                                          .nama
                                                            }
                                                            total={
                                                                props.totalTagihan
                                                            }
                                                            cart={cart}
                                                            diskon={
                                                                props.diskon
                                                            }
                                                            pajak={props.pajak}
                                                            belumLunas={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? true
                                                                    : false
                                                            }
                                                            tunai={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? undefined
                                                                    : Number(
                                                                          props.uangDibayar,
                                                                      )
                                                            }
                                                            kembalian={
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.UTANG ||
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? undefined
                                                                    : Number(
                                                                          props.kembalian,
                                                                      )
                                                            }
                                                            isPro={Number(
                                                                ukmData?.isPro,
                                                            )}
                                                            namaMetode={
                                                                selectedMetode &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.TRANSFER
                                                                    ? selectedMetode.nama
                                                                    : toggleModePembayaran ===
                                                                      ModePembayaran.UTANG
                                                                    ? "Piutang Usaha"
                                                                    : "Tunai"
                                                            }
                                                            html={
                                                                findCustomStruk &&
                                                                findCustomStruk.html !==
                                                                    null
                                                                    ? findCustomStruk?.html
                                                                    : undefined
                                                            }
                                                            keterangan={
                                                                props.keteranganBeli
                                                            }
                                                            noInvoice={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? noInvoicePiutang
                                                                    : noInvoice
                                                            }
                                                            subTotal={
                                                                props.subTotal
                                                            }
                                                            keteranganCustomAmount={
                                                                checkedCustomAmount
                                                                    ? keteranganCustomAmount
                                                                    : undefined
                                                            }
                                                            hargaCustomAmount={
                                                                checkedCustomAmount
                                                                    ? hargaCustomAmount
                                                                    : undefined
                                                            }
                                                            keteranganOngkir={
                                                                checkedOngkir
                                                                    ? keteranganOngkir
                                                                    : undefined
                                                            }
                                                            hargaOngkir={
                                                                checkedOngkir
                                                                    ? hargaOngkir
                                                                    : undefined
                                                            }
                                                            jatuhTempo={
                                                                toggleModePembayaran ===
                                                                ModePembayaran.UTANG
                                                                    ? moment(
                                                                          watchJatuhTempo,
                                                                      ).format(
                                                                          "DD MMM YYYY",
                                                                      )
                                                                    : null
                                                            }
                                                            metodePelanggan={
                                                                watchPelanggan &&
                                                                toggleModePembayaran ===
                                                                    ModePembayaran.UTANG
                                                                    ? watchPelanggan.nama
                                                                    : metodePelanggan
                                                                    ? metodePelanggan
                                                                    : ""
                                                            }
                                                        />
                                                    }
                                                >
                                                    {({
                                                        blob,
                                                        url,
                                                        loading,
                                                        error,
                                                    }) => {
                                                        return (
                                                            <LoadingButton
                                                                disabled={
                                                                    toggleModePembayaran ===
                                                                        ModePembayaran.TRANSFER &&
                                                                    selectedMetode ===
                                                                        null
                                                                }
                                                                loading={
                                                                    loading ||
                                                                    isButtonLoading
                                                                }
                                                                loadingPosition="center"
                                                                variant={
                                                                    toggleModePembayaran ===
                                                                    ModePembayaran.PO
                                                                        ? "outlined"
                                                                        : "contained"
                                                                }
                                                                fullWidth
                                                                size={
                                                                    isTabletScreen
                                                                        ? "medium"
                                                                        : "large"
                                                                }
                                                                href={
                                                                    props.totalTagihan >
                                                                    0
                                                                        ? url
                                                                            ? url
                                                                            : ""
                                                                        : ""
                                                                }
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                onClick={() => {
                                                                    handleSubmit(
                                                                        onSubmit,
                                                                    )();
                                                                }}
                                                            >
                                                                {toggleModePembayaran ===
                                                                ModePembayaran.PO
                                                                    ? "Pre Order"
                                                                    : "Bayar"}
                                                            </LoadingButton>
                                                        );
                                                    }}
                                                </BlobProvider>
                                            )
                                        ) : (
                                            // </Link>
                                            <LoadingButton
                                                disabled={
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER &&
                                                    selectedMetode === null
                                                }
                                                loading={isButtonLoading}
                                                loadingPosition="center"
                                                variant={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.PO
                                                        ? "outlined"
                                                        : "contained"
                                                }
                                                fullWidth
                                                size={
                                                    isTabletScreen
                                                        ? "medium"
                                                        : "large"
                                                }
                                                onClick={handleSubmit(onSubmit)}
                                            >
                                                {toggleModePembayaran ===
                                                ModePembayaran.PO
                                                    ? "Pre Order"
                                                    : "Bayar"}
                                            </LoadingButton>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Stack>
                <Grid
                    container
                    justifyContent="flex-end"
                    display={fullScreen ? undefined : "none"}
                    position="fixed"
                    bottom={0}
                    bgcolor="#fff"
                    zIndex={1}
                >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box
                            paddingTop={3}
                            paddingLeft={3}
                            display="flex"
                            justifyContent="flex-start"
                            borderTop={1}
                            borderColor="#d4d4d4"
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={printStruk}
                                        onChange={(_, checked) => {
                                            handlePrintStruk(checked);
                                        }}
                                    />
                                }
                                label="Print struk"
                                sx={{
                                    height: "fit-content",
                                }}
                            />
                            {printStruk && (
                                <ToggleButtonGroup
                                    value={tipeStruk}
                                    exclusive
                                    size="small"
                                    onChange={handleTipeStruk}
                                >
                                    <ToggleButton
                                        value={TipeStruk.Mobile}
                                        sx={{
                                            display: "flex",
                                            paddingX: 2,
                                        }}
                                    >
                                        <b>A8</b>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={TipeStruk.PC}
                                        sx={{
                                            display: "flex",
                                            paddingX: 2,
                                        }}
                                    >
                                        <b>A4</b>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            )}
                        </Box>
                        <Box
                            paddingLeft={3}
                            display="flex"
                            // justifyContent="flex-start"
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={kirimEmail}
                                        onChange={(_, checked) => {
                                            handleKirimEmail(checked);
                                        }}
                                    />
                                }
                                label="Kirim struk"
                                sx={{
                                    height: "fit-content",
                                }}
                            />
                        </Box>
                        {kirimEmail && (
                            <Box paddingX={3}>
                                <Grid container rowSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack
                                            display="flex"
                                            direction="row"
                                            spacing={1}
                                            sx={{
                                                marginRight: 2,
                                            }}
                                        >
                                            <Button
                                                disabled={
                                                    user &&
                                                    !user.akses.transaksi.includes(
                                                        12,
                                                    )
                                                }
                                                size="medium"
                                                fullWidth
                                                variant={
                                                    toggleKirimStruk ===
                                                    "whatsapp"
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                onClick={() => pilihWhatsapp()}
                                            >
                                                Whatsapp
                                            </Button>
                                            <Button
                                                disabled={
                                                    user &&
                                                    !user.akses.transaksi.includes(
                                                        12,
                                                    )
                                                }
                                                size="medium"
                                                fullWidth
                                                variant={
                                                    toggleKirimStruk === "email"
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                onClick={() => pilihEmail()}
                                            >
                                                Email
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            value={email}
                                            size="small"
                                            type="email"
                                            fullWidth
                                            sx={{ maxWidth: 300 }}
                                            placeholder={
                                                toggleKirimStruk === "email"
                                                    ? "Contoh: budi93@gmail.com"
                                                    : "8316352725"
                                            }
                                            InputProps={{
                                                startAdornment:
                                                    toggleKirimStruk ===
                                                        "whatsapp" && (
                                                        <InputAdornment position="start">
                                                            +62
                                                        </InputAdornment>
                                                    ),
                                            }}
                                            onChange={(
                                                event: React.ChangeEvent<HTMLInputElement>,
                                            ) => setEmail(event.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        <Box
                            marginTop={2}
                            marginLeft={3}
                            marginRight={3}
                            marginBottom={4}
                        >
                            <Stack
                                direction="column"
                                display="flex"
                                justifyContent="space-between"
                                marginBottom={1}
                                spacing={1}
                            >
                                <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Total
                                    </Typography>
                                    <Box
                                        bgcolor="#E8F6ED"
                                        borderRadius={1}
                                        paddingX={2}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                            color="#45A779"
                                        >
                                            Rp. {toRibuan(props.totalTagihan)}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack
                                    direction="row"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Kembalian
                                    </Typography>
                                    <Box
                                        justifyContent="space-between"
                                        bgcolor="#FCEAEA"
                                        paddingX={2}
                                        borderRadius={1}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight="bold"
                                            color="#DC2626"
                                        >
                                            Rp.{" "}
                                            {toggleModePembayaran ===
                                                ModePembayaran.UTANG ||
                                            toggleModePembayaran ===
                                                ModePembayaran.TRANSFER
                                                ? toRibuan(0)
                                                : toRibuan(
                                                      props.kembalian ?? 0,
                                                  )}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            {printStruk ? (
                                tipeStruk === TipeStruk.Mobile ? (
                                    <BlobProvider
                                        document={
                                            <PrintStrukPdf
                                                namaToko={String(
                                                    ukmData?.namaToko,
                                                )}
                                                alamatToko={String(
                                                    ukmData?.alamatToko,
                                                )}
                                                gambarToko={
                                                    ukmData?.gambarTokoBW ===
                                                    null
                                                        ? undefined
                                                        : ukmData?.gambarTokoBW
                                                }
                                                telpToko={String(
                                                    ukmData?.noTelp,
                                                )}
                                                tanggalTransaksi={now}
                                                kasir={props.kasir}
                                                sales={
                                                    props.sales?.nama ===
                                                    undefined
                                                        ? null
                                                        : props.sales.nama
                                                }
                                                total={props.totalTagihan}
                                                cart={cart}
                                                diskon={props.diskon}
                                                pajak={props.pajak}
                                                belumLunas={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? true
                                                        : false
                                                }
                                                tunai={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? undefined
                                                        : Number(
                                                              props.uangDibayar,
                                                          )
                                                }
                                                kembalian={
                                                    toggleModePembayaran ===
                                                        ModePembayaran.UTANG ||
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER
                                                        ? undefined
                                                        : Number(
                                                              props.kembalian,
                                                          )
                                                }
                                                isPro={Number(ukmData?.isPro)}
                                                namaMetode={
                                                    selectedMetode &&
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER
                                                        ? selectedMetode.nama
                                                        : toggleModePembayaran ===
                                                          ModePembayaran.UTANG
                                                        ? "Piutang Usaha"
                                                        : "Tunai"
                                                }
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !==
                                                        null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={
                                                    props.keteranganBeli
                                                }
                                                noInvoice={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? noInvoicePiutang
                                                        : noInvoice
                                                }
                                                subTotal={props.subTotal}
                                                keteranganCustomAmount={
                                                    checkedCustomAmount
                                                        ? keteranganCustomAmount
                                                        : undefined
                                                }
                                                hargaCustomAmount={
                                                    checkedCustomAmount
                                                        ? hargaCustomAmount
                                                        : undefined
                                                }
                                                keteranganOngkir={
                                                    checkedOngkir
                                                        ? keteranganOngkir
                                                        : undefined
                                                }
                                                hargaOngkir={
                                                    checkedOngkir
                                                        ? hargaOngkir
                                                        : undefined
                                                }
                                                jatuhTempo={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? moment(
                                                              watchJatuhTempo,
                                                          ).format(
                                                              "DD MMM YYYY",
                                                          )
                                                        : null
                                                }
                                                metodePelanggan={
                                                    watchPelanggan &&
                                                    toggleModePembayaran ===
                                                        ModePembayaran.UTANG
                                                        ? watchPelanggan.nama
                                                        : metodePelanggan
                                                        ? metodePelanggan
                                                        : ""
                                                }
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    disabled={
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER &&
                                                        selectedMetode === null
                                                    }
                                                    loading={
                                                        loading ||
                                                        isButtonLoading
                                                    }
                                                    loadingPosition="center"
                                                    variant={
                                                        toggleModePembayaran ===
                                                        ModePembayaran.PO
                                                            ? "outlined"
                                                            : "contained"
                                                    }
                                                    fullWidth
                                                    size="large"
                                                    href={
                                                        props.totalTagihan > 0
                                                            ? url
                                                                ? url
                                                                : ""
                                                            : ""
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                        handleSubmit(
                                                            onSubmit,
                                                        )();
                                                    }}
                                                >
                                                    {toggleModePembayaran ===
                                                    ModePembayaran.PO
                                                        ? "Pre Order"
                                                        : "Bayar"}
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ) : (
                                    <BlobProvider
                                        document={
                                            <PrintStrukA4
                                                namaToko={String(
                                                    ukmData?.namaToko,
                                                )}
                                                alamatToko={String(
                                                    ukmData?.alamatToko,
                                                )}
                                                gambarToko={
                                                    ukmData?.gambarTokoBW ===
                                                    null
                                                        ? undefined
                                                        : ukmData?.gambarTokoBW
                                                }
                                                telpToko={String(
                                                    ukmData?.noTelp,
                                                )}
                                                tanggalTransaksi={now}
                                                kasir={props.kasir}
                                                sales={
                                                    props.sales?.nama ===
                                                    undefined
                                                        ? null
                                                        : props.sales.nama
                                                }
                                                total={props.totalTagihan}
                                                cart={cart}
                                                diskon={props.diskon}
                                                pajak={props.pajak}
                                                belumLunas={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? true
                                                        : false
                                                }
                                                tunai={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? undefined
                                                        : Number(
                                                              props.uangDibayar,
                                                          )
                                                }
                                                kembalian={
                                                    toggleModePembayaran ===
                                                        ModePembayaran.UTANG ||
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER
                                                        ? undefined
                                                        : Number(
                                                              props.kembalian,
                                                          )
                                                }
                                                isPro={Number(ukmData?.isPro)}
                                                namaMetode={
                                                    selectedMetode &&
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER
                                                        ? selectedMetode.nama
                                                        : toggleModePembayaran ===
                                                          ModePembayaran.UTANG
                                                        ? "Piutang Usaha"
                                                        : "Tunai"
                                                }
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !==
                                                        null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={
                                                    props.keteranganBeli
                                                }
                                                noInvoice={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? noInvoicePiutang
                                                        : noInvoice
                                                }
                                                subTotal={props.subTotal}
                                                keteranganCustomAmount={
                                                    checkedCustomAmount
                                                        ? keteranganCustomAmount
                                                        : undefined
                                                }
                                                hargaCustomAmount={
                                                    checkedCustomAmount
                                                        ? hargaCustomAmount
                                                        : undefined
                                                }
                                                keteranganOngkir={
                                                    checkedOngkir
                                                        ? keteranganOngkir
                                                        : undefined
                                                }
                                                hargaOngkir={
                                                    checkedOngkir
                                                        ? hargaOngkir
                                                        : undefined
                                                }
                                                jatuhTempo={
                                                    toggleModePembayaran ===
                                                    ModePembayaran.UTANG
                                                        ? moment(
                                                              watchJatuhTempo,
                                                          ).format(
                                                              "DD MMM YYYY",
                                                          )
                                                        : null
                                                }
                                                metodePelanggan={
                                                    watchPelanggan &&
                                                    toggleModePembayaran ===
                                                        ModePembayaran.UTANG
                                                        ? watchPelanggan.nama
                                                        : metodePelanggan
                                                        ? metodePelanggan
                                                        : ""
                                                }
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    disabled={
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER &&
                                                        selectedMetode === null
                                                    }
                                                    loading={
                                                        loading ||
                                                        isButtonLoading
                                                    }
                                                    loadingPosition="center"
                                                    variant={
                                                        toggleModePembayaran ===
                                                        ModePembayaran.PO
                                                            ? "outlined"
                                                            : "contained"
                                                    }
                                                    fullWidth
                                                    size="large"
                                                    href={
                                                        props.totalTagihan > 0
                                                            ? url
                                                                ? url
                                                                : ""
                                                            : ""
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    onClick={() => {
                                                        handleSubmit(
                                                            onSubmit,
                                                        )();
                                                    }}
                                                >
                                                    {toggleModePembayaran ===
                                                    ModePembayaran.PO
                                                        ? "Pre Order"
                                                        : "Bayar"}
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                )
                            ) : (
                                // </Link>
                                <LoadingButton
                                    disabled={
                                        toggleModePembayaran ===
                                            ModePembayaran.TRANSFER &&
                                        selectedMetode === null
                                    }
                                    loading={isButtonLoading}
                                    loadingPosition="center"
                                    variant={
                                        toggleModePembayaran ===
                                        ModePembayaran.PO
                                            ? "outlined"
                                            : "contained"
                                    }
                                    fullWidth
                                    size="large"
                                    onClick={handleSubmit(onSubmit)}
                                >
                                    {toggleModePembayaran === ModePembayaran.PO
                                        ? "Pre Order"
                                        : "Bayar"}
                                </LoadingButton>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
            <ModalAlertPro
                isOpenModalAlertPro={isOpenModalAlertPro}
                closeModalAlertPro={() => setIsOpenModalAlertPro(false)}
                namaToko={ukmData?.namaToko}
            />
        </Box>
    );
};

export default ModalKonfirmasiBayar;
