import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import LogoUlo from "../../components/LogoUlo/LogoUlo";
import ThemeDefaultComponent from "../../components/ThemeDefaultComponent/ThemeDefaultComponent";
import Stack from "@mui/material/Stack";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import axios from "../../services/axios";
import { LupaPasswordResponse } from "../../constants/types";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import defaultAxios, { AxiosError } from "axios";

interface IResetPasswordProps {}

interface IResetPasswordInput {
    passwordBaru: string;
    passwordBaruUlang: string;
}

const schema = yup
    .object({
        passwordBaru: yup
            .string()
            .required("Kolom wajib diisi")
            .min(8, "Kata sandi minimal 8 karakter"),
        passwordBaruUlang: yup
            .string()
            .required("Kolom wajib diisi")
            .oneOf([yup.ref("passwordBaru")], "Kata sandi baru tidak sama"),
    })
    .required();

const ResetPassword = (props: IResetPasswordProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [showPasswordBaru, setShowPasswordBaru] = useState(false);
    const [showPasswordBaruUlang, setShowPasswordBaruUlang] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const history = useHistory();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryToken = urlParams.get("token");

    const initialValues = useMemo(
        () => ({
            passwordBaru: "",
        }),
        [],
    );

    const {
        handleSubmit,
        setError,
        control,
        formState: { errors },
    } = useForm<IResetPasswordInput>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const handleErrorReponse = (error: any) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError?.response) {
                const fieldError = serverError?.response?.data;
                if (fieldError?.errors) {
                    Object.keys(fieldError.errors).forEach((key) => {
                        const errorMessage = fieldError.errors[key];
                        setError(key as keyof typeof initialValues, {
                            type: "manual",
                            message: errorMessage[0],
                        });
                    });
                } else {
                    Swal.fire({
                        title: `${fieldError.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        }
    };

    const onSubmit = async (values: IResetPasswordInput) => {
        console.log("Password: ", values);
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("token", queryToken ? queryToken : "nothing");
            formData.append("password", values.passwordBaru);
            const { data } = await axios.post<LupaPasswordResponse>(
                `/api/prosesgantipass`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                // assignWords("Kata sandi berhasil diubah");
                // openAlert();
                setIsButtonLoading(false);
                Swal.fire({
                    title: `${data.message}`,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                history.push(`/`);
            } else {
                setIsButtonLoading(false);
                Swal.fire({
                    title: `${data.message}`,
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorReponse(error);
            console.error(error);
        }
    };

    return (
        <ThemeDefaultComponent>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100vh" }}
            >
                <Box
                    minHeight="50vh"
                    minWidth="50vw"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="fit-content"
                    paddingX={isTabletScreen ? 4 : undefined}
                    paddingY={4}
                    border="1px solid rgba(0, 0, 0, 0.12)"
                    borderRadius="6px"
                    sx={{ backgroundColor: "#fff" }}
                >
                    <Stack spacing={3} alignItems="center">
                        <Link
                            href="https://bumdes-penjualan.noretest2.com/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <LogoUlo width="100px" />
                        </Link>
                        <Box>
                            <Typography
                                component="div"
                                textAlign="center"
                                variant="h6"
                                fontWeight="bold"
                            >
                                Atur Ulang{" "}
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: "#20965F" }}
                                    display="inline"
                                >
                                    Kata Sandi
                                </Typography>
                            </Typography>
                            <Typography
                                marginTop={1}
                                variant="body2"
                                textAlign="center"
                                width="300px"
                            >
                                Masukkan kata sandi baru anda.
                            </Typography>
                        </Box>
                        <form
                            style={{
                                width: "100%",
                                maxWidth: "600px",
                                minWidth: "300px",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Password
                                    </Typography>
                                    <Controller
                                        name="passwordBaru"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.passwordBaru,
                                                )}
                                            >
                                                <OutlinedInput
                                                    id="passwordBaru"
                                                    type={
                                                        showPasswordBaru
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    autoComplete="passwordBaru"
                                                    autoFocus={isLaptopScreen}
                                                    placeholder="Minimal 8 karakter"
                                                    size="small"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowPasswordBaru(
                                                                        (
                                                                            prev,
                                                                        ) =>
                                                                            !prev,
                                                                    );
                                                                }}
                                                                edge="end"
                                                            >
                                                                {showPasswordBaru ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.passwordBaru
                                                        ? errors.passwordBaru
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Ulang Password
                                    </Typography>
                                    <Controller
                                        name="passwordBaruUlang"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(
                                                    errors.passwordBaruUlang,
                                                )}
                                            >
                                                <OutlinedInput
                                                    id="passwordBaruUlang"
                                                    type={
                                                        showPasswordBaruUlang
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    autoComplete="passwordBaruUlang"
                                                    autoFocus={isLaptopScreen}
                                                    placeholder="Minimal 8 karakter"
                                                    size="small"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowPasswordBaruUlang(
                                                                        (
                                                                            prev,
                                                                        ) =>
                                                                            !prev,
                                                                    );
                                                                }}
                                                                edge="end"
                                                            >
                                                                {showPasswordBaruUlang ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.passwordBaruUlang
                                                        ? errors
                                                              .passwordBaruUlang
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                type="submit"
                                fullWidth
                                size="medium"
                                variant="contained"
                                sx={{ marginTop: 3 }}
                            >
                                Ganti kata sandi
                            </LoadingButton>
                        </form>
                    </Stack>
                </Box>
            </Box>
        </ThemeDefaultComponent>
    );
};

export default ResetPassword;
