import React, { useEffect, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
    SelectedProductMutasi,
    // AddMutasiBody,
    AddMutasiResponse,
    MutasiValues,
    NestedDetail,
} from "../../../constants/types";
// import toRupiah from "../../../utils/toRupiah";
import { LoadingButton } from "@mui/lab";
// import id from "date-fns/locale/id";
// import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import BarangTerpilih from "../BarangTerpilih/BarangTerpilih";
import { useAuth } from "../../../context/authContext";
import { useMutasiStokModal } from "../../../context/mutasiStokModalContext";
import axios from "../../../services/axios";
import Swal from "sweetalert2";
import { useTransaksi } from "../../../context/transaksiContext";
import formatHarga from "../../../utils/formatHarga";

// registerLocale("id", id);
// setDefaultLocale("id");

interface IDetailItemMutasiStepProps {
    products: SelectedProductMutasi[];
    onBack: () => void;
}

const DetailItemMutasiStep = ({
    products,
    onBack,
}: IDetailItemMutasiStepProps) => {
    const today = new Date();
    const now = today.toLocaleString("id", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    const { ukmIdUser } = useAuth();
    const { setFetchingItems } = useTransaksi();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { isOpenModalMutasiStok, closeModalMutasiStok } =
        useMutasiStokModal();
    // const [startDate, setStartDate] = useState<Date | null>(new Date());

    const initialValues: MutasiValues = useMemo(
        () => ({
            ukmId: ukmIdUser && ukmIdUser[0],
            detail: products.map((li) => ({
                nestedDetail: [
                    {
                        itemId: li.id,
                        jumlahStok: "",
                        // hargaBeli: 0,
                        keterangan: "",
                        isMasuk: "masuk",
                        faktur: li.hpp[0],
                        stokRepack: "",
                        itemRepack: null,
                        hargaBeliRepack: null,
                    },
                ],
                // itemId: li.id,
                // jumlahStok: "",
                // // hargaBeli: 0,
                // keterangan: "",
                // isMasuk: "masuk",
                // faktur: li.hpp[0],
                // stokRepack: "",
                // itemRepack: null,
                // hargaBeliRepack: null,
            })) ?? [
                {
                    itemId: 1,
                    jumlahStok: 1,
                    // hargaBeli: 0,
                    keterangan: "",
                    isMasuk: "masuk",
                    faktur: {
                        id: 0,
                        nama: "Faktur",
                    },
                    stokRepack: "",
                    itemRepack: null,
                },
            ],
        }),
        [products, ukmIdUser],
    );

    const methods = useForm<MutasiValues>({
        // resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: MutasiValues) => {
        // alert(JSON.stringify(values));
        setIsButtonLoading(true);

        let newValues: NestedDetail[] = [];
        values.detail.forEach((li) =>
            li.nestedDetail.forEach((nd) => {
                newValues.push(nd);
            }),
        );
        console.log(newValues);
        try {
            const body = {
                ukmId: values.ukmId,
                detail: newValues.map((li) => ({
                    tipe: li.isMasuk === "repack" ? "repack" : undefined,
                    itemIdAwal: li.isMasuk === "repack" ? li.itemId : undefined,
                    itemIdFaktur:
                        li.isMasuk === "repack" ? li.faktur?.id : undefined,
                    jumlahKeluar:
                        li.isMasuk === "repack"
                            ? Number(li.jumlahStok)
                            : li.isMasuk === "keluar"
                            ? Number(li.jumlahStok)
                            : 0,
                    itemIdTujuan:
                        li.isMasuk === "repack" ? li.itemRepack?.id : undefined,
                    jumlahMasuk:
                        li.isMasuk === "repack"
                            ? Number(li.stokRepack)
                            : li.isMasuk === "masuk"
                            ? Number(li.jumlahStok)
                            : 0,
                    hargaBeli:
                        li.isMasuk === "repack"
                            ? formatHarga(String(li.hargaBeliRepack))
                            : li.faktur?.hargaBeli,
                    kodeFaktur: li.isMasuk === "repack" ? null : undefined,
                    itemId: li.isMasuk === "repack" ? undefined : li.itemId,
                    keterangan: li.keterangan,
                    fakturId:
                        li.isMasuk === "repack" ? undefined : li.faktur?.id,
                })),
            };
            console.log(body);
            const { data } = await axios.post<AddMutasiResponse>(
                `/api/mutasiitem`,
                body,
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Stok telah dimutasi",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setFetchingItems();
                closeModalMutasiStok();
            }
            setIsButtonLoading(false);
            console.log(data);
        } catch (error) {
            setIsButtonLoading(false);
            // if (defaultAxios.isAxiosError(error)) {
            //     const serverError = error as AxiosError<
            //         ErrorFieldResponse | undefined
            //     >;
            //     if (serverError && serverError?.response) {
            //         const fieldError = serverError?.response?.data;
            //         if (fieldError?.errors) {
            //             Object.keys(fieldError.errors).forEach((key) => {
            //                 const errorMessage = fieldError.errors[key];
            //                 setError(key as keyof typeof initialValues, {
            //                     type: "manual",
            //                     message: errorMessage[0],
            //                 });
            //             });
            //         }
            //     }
            // }
            console.error(error);
        }
    };

    useEffect(() => {
        if (isOpenModalMutasiStok) {
            methods.reset(initialValues);
        }
    }, [initialValues, isOpenModalMutasiStok, methods]);

    return (
        <React.Fragment>
            <Typography fontWeight="bold" component="div">
                Tanggal:{" "}
                <Typography display="inline" color="GrayText">
                    {now}
                </Typography>
            </Typography>
            <Stack mt={2} mb={5} direction="column" spacing={2}>
                {products &&
                    products?.map((li, idx) => (
                        <FormProvider key={String(li.id)} {...methods}>
                            <BarangTerpilih
                                id={li.id}
                                nama={li.nama}
                                sku={li.sku}
                                idx={idx}
                                hpp={li.hpp}
                                totalStok={li.stok}
                            />
                        </FormProvider>
                    ))}
            </Stack>
            {/* <Box marginTop={4} marginBottom={1}>
                <DatePicker
                    wrapperClassName="wrapper-datePicker"
                    className="range-filter"
                    dateFormat="dd/MM/yyyy"
                    onFocus={(e) => e.target.blur()}
                    startDate={startDate}
                    onChange={(date) => setStartDate(date)}
                    customInput={
                        <Stack
                            display="flex"
                            justifyContent="space-between"
                            direction="row"
                            // width="100%"
                            bgcolor="#FFFF"
                            height="40px"
                        >
                            <Typography>
                                {startDate?.toLocaleDateString("id-ID")}
                            </Typography>
                            <ArrowDropDownIcon
                                sx={{
                                    marginRight: -3,
                                }}
                            />
                        </Stack>
                    }
                />
            </Box> */}
            {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <TextField
                        label="Keterangan"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid> */}
            <Box
                mt={4}
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                justifyContent="flex-end"
                columnGap="16px"
            >
                <div />
                <Button onClick={onBack} variant="outlined">
                    Kembali
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    Simpan
                </LoadingButton>
            </Box>
        </React.Fragment>
    );
};

export default DetailItemMutasiStep;
