import React from "react";
import Ulo from "../../assets/logo/bumdes-logo.png";

interface ILogoUloProps {
    width?: string | number | undefined;
    height?: string | number | undefined;
}

const LogoUlo = ({ width, height }: ILogoUloProps) => {
    return <img src={Ulo} alt="Logo Astramaya" width={width} height={height} />;
};

export default LogoUlo;
