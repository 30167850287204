import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    DialogTitle,
} from "@mui/material";
import React from "react";
import { useAuth } from "../../context/authContext";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

interface IModalTundaTokoProps {
    closeModalTundaToko: () => void;
    isOpenModalTundaToko: boolean;
}

const ModalTundaToko = ({
    closeModalTundaToko,
    isOpenModalTundaToko,
}: IModalTundaTokoProps) => {
    const { logout, isLoading } = useAuth();

    const handleKeluar = () => {
        logout();
        closeModalTundaToko();
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={isOpenModalTundaToko}
                onClose={closeModalTundaToko}
                PaperProps={{
                    sx: {
                        //   minHeight: "150px",
                        //   maxHeight: "150px",
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>Tunda pembuatan BUMDes?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Anda yakin ingin menunda pembuatan BUMDes dan keluar
                        dari sistem?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={handleKeluar}
                        color="error"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Ya, Tunda
                    </Button>
                    <Button
                        variant="contained"
                        onClick={closeModalTundaToko}
                        color="error"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Tidak
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingOverlay open={isLoading} />
        </>
    );
};

export default ModalTundaToko;
