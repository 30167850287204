import {
    Button,
    Card,
    CardContent,
    Box,
    Avatar,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import avatarAlt from "../../utils/avatarAlt";
import { useUkmModal } from "../../context/ukmModalContext";
// import { useHapusUkmModal } from "../../context/hapusUkmModalContext";

interface ICardInfoTokoProps {
    id?: number;
    gambarToko: string | null;
    namaToko: string;
    warna: string;
    alamatToko: string;
    noTelp: string;
    deskripsi: string | null;
    besarUsaha: string;
    jenisUsaha: string;
    omset: number;
    isPro: number;
    isActive: number | null;
}

const CardInfoToko = ({
    id,
    gambarToko,
    namaToko,
    warna,
    alamatToko,
    noTelp,
    deskripsi,
    besarUsaha,
    jenisUsaha,
    omset,
    isPro,
    isActive,
}: ICardInfoTokoProps) => {
    const { openModalUkm } = useUkmModal();
    // const { openModalHapusUkm } = useHapusUkmModal();

    return (
        <Card>
            <CardContent>
                <Box display="flex" justifyContent="center">
                    <Avatar
                        alt={namaToko}
                        src={gambarToko === null ? "" : gambarToko}
                        sx={{
                            bgcolor: "primary.main",
                            width: 100,
                            height: 100,
                            marginY: 3,
                        }}
                    >
                        {avatarAlt(namaToko)}
                    </Avatar>
                </Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h6" fontWeight="bold">
                        {namaToko}
                    </Typography>
                    <Box
                        bgcolor={warna ?? "primary.main"}
                        width={22}
                        height={22}
                        borderRadius="50%"
                    />
                </Stack>
                <Typography
                    component="div"
                    variant="body2"
                    color="#808080"
                    sx={{ marginBottom: 2 }}
                >
                    Status:{" "}
                    <Typography
                        display="inline"
                        variant="body2"
                        color={isPro === 1 ? "#45A779" : "#808080"}
                    >
                        {isPro === 1 ? "Pro " : "Free "}
                    </Typography>
                    <Typography
                        display="inline"
                        variant="body2"
                        color={isActive === 1 ? "#45A779" : "error"}
                    >
                        {isActive === 1 ? "(Aktif)" : "(Nonaktif)"}
                    </Typography>
                </Typography>
                <Typography variant="body2" sx={{ marginBottom: 2 }}>
                    No. Telp: {noTelp}
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="contained"
                        size="large"
                        disabled={isActive === 0}
                        fullWidth
                        onClick={() =>
                            openModalUkm({
                                id: id,
                                // gambarToko: gambarToko ?? null,
                                namaToko: namaToko,
                                warna: warna,
                                alamatToko: alamatToko,
                                noTelp: noTelp,
                                deskripsi: deskripsi ?? "",
                                besarUsaha: besarUsaha,
                                jenisUsaha: jenisUsaha,
                                omset: String(omset),
                            })
                        }
                    >
                        Ubah
                    </Button>
                    {/* <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        color="error"
                        onClick={() => openModalHapusUkm(id, namaToko)}
                    >
                        Hapus
                    </Button> */}
                </Stack>
            </CardContent>
        </Card>
    );
};

export default CardInfoToko;
