import { useQuery } from "react-query";
import { GetPinDiskonResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async () => {
    const { data } = await axios.get<GetPinDiskonResponse>("/api/setdiskon");
    return data.data;
};

export default function usePinDiskon() {
    return useQuery<GetPinDiskonResponse["data"]>(["pinDiskon"], () =>
        handleRequest(),
    );
}
