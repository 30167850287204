import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import LogoUlo from "../LogoUlo/LogoUlo";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useNoWaAdmin from "../../services/queries/useNoWaAdmin";

interface ITokoTidakAktifProps {
    namaToko?: string;
    label?: string;
}

const TokoTidakAktif = ({ namaToko, label }: ITokoTidakAktifProps) => {
    const theme = useTheme();
    const { data: noWaAdmin } = useNoWaAdmin();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="#ffffff"
            gap={3}
            padding={2}
            sx={{
                height: isTabletScreen
                    ? "calc(100vh - 64px)"
                    : isPhoneScreen
                    ? "calc(100vh - 56px)"
                    : "100vh",
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
            >
                <LogoUlo width="100px" />
                <Typography marginTop={2} textAlign="center">
                    {label ?? "Mohon maaf, BUMDes anda berstatus tidak aktif"}
                </Typography>
            </Box>
            {namaToko && (
                <Button
                    variant="outlined"
                    color="ulo"
                    startIcon={<WhatsAppIcon />}
                    target="_blank"
                    href={`https://api.whatsapp.com/send?phone=${noWaAdmin?.nomor}&text=Kenapa%20BUMDes%20saya%20yaitu%20${namaToko}%20berstatus%20tidak%20aktif?`}
                    rel="noreferrer"
                >
                    Hubungi Kami
                </Button>
            )}
        </Box>
    );
};

export default TokoTidakAktif;
