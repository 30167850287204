export const apiUrl =
    process.env.REACT_APP_API_URL ?? "https://admin.abumdes.com/";
export const tokenKey = "@token_key";
export const accessTokenKey = "@access_token_key";
export const captchaSiteKey =
    process.env.REACT_APP_CAPTCHA_SITE_KEY ??
    "6LccfyYgAAAAAJUkNlK5YI0htwVmSfnkVblaAwKZ";
export const captchaSiteKey2 =
    process.env.REACT_APP_CAPTCHA_SITE_KEY_2 ??
    "6LeLm4ciAAAAAPpbSXYSyGoDYiKqj1Aefxgvlwbv";

export const logoNore = "https://i.imgur.com/pmiSFwv.png";
export const imgPlaceholder = "https://i.imgur.com/Wu662Ir.jpg";

export const PEMILIK_TOKO = 10;
export const PEGAWAI_TOKO = 20;

export const font = "'Open Sans', sans-serif";
export const defaultColor = "#2D7676";

export const tipeOptions = [
    { id: "Pieces", nama: "Pieces" },
    { id: "Carton", nama: "Carton" },
];
