import React, { useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    Grid,
    useMediaQuery,
    Card,
    TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PrintBarcode from "../PrintBarcode/PrintBarcode";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { pdf } from "@react-pdf/renderer";
import { usePrintBarcodeModal } from "../../context/printBarcodeModal";
import { LoadingButton } from "@mui/lab";

interface IModalPrintBarcodeProps {}

const ModalPrintBarcode = (props: IModalPrintBarcodeProps) => {
    var Barcode = require("react-barcode");
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [jumlahCetak, setJumlahCetak] = useState<string>("1");
    const {
        isOpenModalPrintBarcode,
        sku,
        closeModalPrintBarcode,
        namaBarang,
        hargaBarang,
        isLabel,
    } = usePrintBarcodeModal();
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    const handlingPrintBarcode = () => {
        setIsLoadingButton(true);
        var obj = pdf(
            <PrintBarcode
                jumlahCetak={Number(jumlahCetak)}
                valueBarcode={sku ?? ""}
                namaBarang={String(namaBarang)}
                hargaBarang={String(hargaBarang)}
                sku={String(sku)}
                isLabel={isLabel}
            />,
        ).toBlob();
        obj.then(function (blob) {
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank");
            setIsLoadingButton(false);
            closeModalPrintBarcode();
            // setJumlahCetak(1);
        });
    };

    return (
        <>
            <Dialog
                maxWidth="sm"
                fullScreen={isPhoneScreen}
                fullWidth={true}
                open={isOpenModalPrintBarcode}
                onClose={() => {
                    closeModalPrintBarcode();
                    // setJumlahCetak(1);
                }}
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            onClick={() => {
                                closeModalPrintBarcode();
                                // setJumlahCetak(1);
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Cetak Barcode
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {/* <Grid marginBottom={3}> */}
                    {/* <Grid container alignItems="center" marginBottom={2}>
                        <Grid item xs={6}>
                            <Typography>Gunakan mode label?</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isLabel}
                                            onChange={handleChangeLabel}
                                        />
                                    }
                                    label={isLabel ? "Ya" : "Tidak"}
                                    componentsProps={{
                                        typography: {
                                            variant: "body2",
                                        },
                                    }}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid> */}
                    <Typography variant="body2" color="#737373">
                        Anda dapat melakukan cetak barcode berdasarkan SKU
                        barang.
                    </Typography>
                    <Stack direction="column" spacing={0.4} marginBottom={2}>
                        <Typography variant="body2" color="#737373">
                            Pastikan kertas yang digunakan untuk mencetak adalah{" "}
                            <b>{isLabel ? "Label no. 109" : "Kertas A4"}</b>.
                        </Typography>
                    </Stack>
                    {isLabel ? (
                        <Card
                            variant="outlined"
                            sx={{ padding: 1, width: "fit-content" }}
                        >
                            <Stack
                                direction="column"
                                spacing={0}
                                alignItems="center"
                            >
                                <Barcode
                                    value={sku ?? ""}
                                    height={70}
                                    fontSize={16}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                marginTop="-4px"
                                paddingX={1}
                            >
                                <Typography fontFamily="monospace">
                                    {namaBarang?.toUpperCase()}
                                </Typography>
                                <Typography fontFamily="monospace">
                                    {hargaBarang}
                                </Typography>
                            </Stack>
                        </Card>
                    ) : (
                        <Card
                            variant="outlined"
                            sx={{ padding: 1, width: "fit-content" }}
                        >
                            <Stack
                                direction="column"
                                spacing={0}
                                alignItems="center"
                            >
                                <Barcode
                                    value={sku ?? ""}
                                    height={70}
                                    fontSize={16}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                marginTop="-4px"
                                paddingX={1}
                            >
                                <Typography fontFamily="monospace">
                                    {namaBarang?.toUpperCase()}
                                </Typography>
                                <Typography fontFamily="monospace">
                                    {hargaBarang}
                                </Typography>
                            </Stack>
                        </Card>
                    )}
                    <Grid item marginTop={2} marginBottom={6} lg={9}>
                        <Typography variant="body2" color="#737373">
                            Jumlah yang ingin dicetak (maks.{" "}
                            {isLabel ? "55" : "150"})
                        </Typography>
                        <TextField
                            placeholder="Masukkan jumlah yang ingin dicetak"
                            fullWidth
                            value={jumlahCetak}
                            type="number"
                            onChange={(e) => setJumlahCetak(e.target.value)}
                        />
                        {isLabel
                            ? Number(jumlahCetak) > 55
                            : Number(jumlahCetak) > 150 && (
                                  <Typography
                                      marginTop={1}
                                      variant="body2"
                                      color="#dc2626"
                                      fontWeight={600}
                                  >
                                      Jumlah cetak melebihi maksimal
                                  </Typography>
                              )}
                    </Grid>
                    <LoadingButton
                        loading={isLoadingButton}
                        loadingPosition="center"
                        variant="contained"
                        fullWidth={isPhoneScreen}
                        onClick={() => handlingPrintBarcode()}
                        disabled={
                            (isLabel
                                ? Number(jumlahCetak) > 55
                                : Number(jumlahCetak) > 150) ||
                            Number(jumlahCetak) <= 0
                        }
                    >
                        Cetak
                    </LoadingButton>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ModalPrintBarcode;
