import { useQuery } from "react-query";
import {
    ParamsGetListPreOrder,
    GetListPreOrderResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetListPreOrder) => {
    const api = qs.stringifyUrl({
        url: "api/transaksilistpo",
        query: {
            ukmId: params.ukmId,
            search: params.search,
            size: params.size,
            page: params.page,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
            sales: params.sales,
            kasir: params.kasir,
            metodeId: params.metodeId,
            void: params.voidInvoice,
        },
    });
    const { data } = await axios.get<GetListPreOrderResponse>(api);
    return data.data;
};

export default function useListPreOrder(params: ParamsGetListPreOrder) {
    return useQuery<GetListPreOrderResponse["data"]>(
        ["listPreOrder", params],
        () => handleRequest(params),
    );
}
