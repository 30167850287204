import { useQuery } from "react-query";
import {
    ParamsGetLaporanMetodePembayaran,
    GetLaporanMetodePembayaranResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetLaporanMetodePembayaran) => {
    const apiGetLaporanMetodePembayaran = qs.stringifyUrl({
        url: "api/kalkulasimetodebayar", // GANTI SETELAH ADA BE
        query: {
            tglawal: params.tglawal,
            tglakhir: params.tglakhir,
        },
    });
    const { data } = await axios.get<GetLaporanMetodePembayaranResponse>(
        apiGetLaporanMetodePembayaran,
    );
    return data.data;
};

export default function useLaporanMetodePembayaran(
    params: ParamsGetLaporanMetodePembayaran,
) {
    return useQuery<GetLaporanMetodePembayaranResponse["data"]>(
        ["LaporanMetodePembayaran", params],
        () => handleRequest(params),
    );
}
