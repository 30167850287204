import { useQuery } from "react-query";
import { GetDetailInvoicePOResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async (id: number) => {
    const { data } = await axios.get<GetDetailInvoicePOResponse>(
        `/api/detailpo/${id}`,
    );
    return data.data;
};

export default function useDetailInvoicePO(id: number) {
    return useQuery<GetDetailInvoicePOResponse["data"]>(
        ["detailInvoicePO", id],
        () => handleRequest(id),
        { enabled: !!id },
    );
}
