import {
    Grid,
    Card,
    CardContent,
    Typography,
    Stack,
    Avatar,
    Button,
    TextField,
    Input,
    Box,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import avatarAlt from "../../utils/avatarAlt";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useUser from "../../services/queries/useUser";
import {
    EditUserResponse,
    ErrorFieldResponse,
    UserValues,
} from "../../constants/types";
import axios from "../../services/axios";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";

interface ICardInfoUserProps {}

const schema = yup.object({
    namaLengkap: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    username: yup
        .string()
        .required("Kolom wajib diisi")
        .min(6, "Username terlalu pendek")
        .max(50, "Username terlalu panjang"),
    email: yup
        .string()
        .email("Format email salah")
        .required("Kolom wajib diisi")
        .max(50, "Email terlalu panjang"),
    gambar: yup.mixed(),
    // .nullable()
    // .test("fileType", "Gambar harus berformat jpg/jpeg/png", (value) =>
    //     ["image/jpg", "image/jpeg", "image/png"].includes(value.type),
    // ),
});

const CardInfoUser = (props: ICardInfoUserProps) => {
    const { data: user, refetch } = useUser();
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [image, setImage] = useState<string>(String(user?.gambar));

    const initialValues = useMemo(
        () => ({
            namaLengkap: user?.namaLengkap,
            username: user?.username,
            email: user?.email,
            gambar: user?.gambar,
            noTelp: user?.noTelp ?? "",
        }),
        [
            user?.email,
            user?.gambar,
            user?.namaLengkap,
            user?.noTelp,
            user?.username,
        ],
    );

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
        setValue,
        setError,
        clearErrors,
        getValues,
    } = useForm<UserValues>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: UserValues) => {
        setIsButtonLoading(true);
        console.log(values);
        try {
            const formData = new FormData();
            formData.append("namaLengkap", values.namaLengkap);
            formData.append("username", values.username);
            formData.append("email", values.email);
            formData.append("noTelp", String(values.noTelp));
            if (values.gambar && typeof values.gambar !== "string") {
                formData.append("gambar", values.gambar as any);
            }
            const { data } = await axios.post<EditUserResponse>(
                `/api/users`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                // assignWords("Pengaturan akun berhasil diubah");
                // openAlert();
                Swal.fire({
                    title: "Pengaturan akun berhasil diubah",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(true);
            reset(initialValues);
            // console.log(data.nama);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
            setIsSuccessSubmit(false);
            console.error(error);
        }
    };

    useEffect(() => {
        if (isSuccessSubmit === true) {
            refetch();
        }
        setIsSuccessSubmit(false);
        console.log(isSuccessSubmit);
    }, [isSuccessSubmit, refetch]);

    useEffect(() => {
        if (user) {
            reset(initialValues);
        }
    }, [initialValues, reset, user]);

    useEffect(() => {
        console.log("Image:", image);
        getValues();
    }, [getValues, image]);

    return (
        <Grid item xs={12} sm={12} md={6}>
            <Card variant="outlined" sx={{ height: "620px" }}>
                <CardContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                    }}
                >
                    <Typography variant="h6" fontWeight="bold">
                        Detail Akun
                    </Typography>
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="flex-end"
                        marginY={3}
                    >
                        <Controller
                            name="gambar"
                            control={control}
                            render={({ field }) => (
                                <React.Fragment>
                                    <Avatar
                                        alt={user?.namaLengkap}
                                        src={
                                            typeof field.value !== "string"
                                                ? image
                                                : typeof field.value ===
                                                  "string"
                                                ? field.value
                                                : user?.namaLengkap
                                            // ? field.value
                                        }
                                        sx={{
                                            bgcolor: "primary.main",
                                            width: 100,
                                            height: 100,
                                        }}
                                    >
                                        {avatarAlt(String(user?.namaLengkap))}
                                    </Avatar>
                                    <Box>
                                        <label htmlFor="outlined-button-file">
                                            <Input
                                                inputProps={{
                                                    accept: "image/*",
                                                    style: {
                                                        display: "none",
                                                    },
                                                }}
                                                id="outlined-button-file"
                                                type="file"
                                                onChange={(event: any) => {
                                                    if (
                                                        event.target.files[0] &&
                                                        event.target.files[0]
                                                            .size < 2097152
                                                    ) {
                                                        console.log(
                                                            event.target
                                                                .files[0],
                                                        );
                                                        setImage(
                                                            URL.createObjectURL(
                                                                event.target
                                                                    .files[0],
                                                            ),
                                                        );
                                                        setValue(
                                                            "gambar",
                                                            event.target
                                                                .files[0],
                                                        );
                                                        clearErrors("gambar");
                                                    } else {
                                                        event.target.value = "";
                                                        setError("gambar", {
                                                            type: "manual",
                                                            message:
                                                                "Ukuran gambar harus kurang dari 2MB",
                                                        });
                                                    }
                                                }}
                                            />
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                component="span"
                                            >
                                                Ubah Gambar
                                            </Button>
                                        </label>
                                        <Box>
                                            <Typography
                                                variant="body2"
                                                color="#6D6D6D"
                                            >
                                                *Resolusi optimal: 300 x 300,
                                                Ukuran maksimal: 2MB
                                            </Typography>
                                            {errors.gambar ? (
                                                <Typography
                                                    variant="caption"
                                                    color="#FF0000"
                                                >
                                                    {errors.gambar.message}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    variant="caption"
                                                    color="#FFF"
                                                >
                                                    null
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </React.Fragment>
                            )}
                        />
                    </Stack>
                    <Grid container alignItems="center" marginTop={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body2" color="#808080">
                                Nama
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Controller
                                name="namaLengkap"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="namaLengkap"
                                        fullWidth
                                        size="small"
                                        placeholder="Contoh: Budi Saputra"
                                        sx={{ maxWidth: "500px" }}
                                        error={Boolean(errors.namaLengkap)}
                                        helperText={
                                            errors.namaLengkap
                                                ? errors.namaLengkap.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body2" color="#808080">
                                Username
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Controller
                                name="username"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="username"
                                        fullWidth
                                        size="small"
                                        placeholder="Contoh: budi93"
                                        sx={{
                                            maxWidth: "500px",
                                        }}
                                        inputProps={{
                                            style: {
                                                textTransform: "lowercase",
                                            },
                                        }}
                                        error={Boolean(errors.username)}
                                        helperText={
                                            errors.username
                                                ? errors.username.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body2" color="#808080">
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="email"
                                        fullWidth
                                        size="small"
                                        type="email"
                                        placeholder="Contoh: budi93@gmail.com"
                                        sx={{ maxWidth: "500px" }}
                                        error={Boolean(errors.email)}
                                        helperText={
                                            errors.email
                                                ? errors.email.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body2" color="#808080">
                                No. Telp
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Controller
                                name="noTelp"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="noTelp"
                                        fullWidth
                                        size="small"
                                        placeholder="62831xxxxxx"
                                        sx={{ maxWidth: "500px" }}
                                        error={Boolean(errors.noTelp)}
                                        helperText={
                                            errors.noTelp
                                                ? errors.noTelp.message
                                                : " "
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <LoadingButton
                        disabled={!!errors.gambar}
                        loading={isButtonLoading}
                        loadingPosition={"center"}
                        variant="contained"
                        size="large"
                        onClick={handleSubmit((data) => onSubmit(data))}
                        type="submit"
                        sx={{ marginTop: 2, width: "fit-content" }}
                    >
                        Simpan
                    </LoadingButton>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CardInfoUser;
