import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import CssBaseline from "@mui/material/CssBaseline";
import { defaultColor } from "../../constants/common";

interface IThemeDefaultComponentProps {
    children: React.ReactNode;
}

const font = "'Open Sans', sans-serif";

const ThemeDefaultComponent = ({ children }: IThemeDefaultComponentProps) => {
    const loginTheme = createTheme({
        palette: {
            primary: {
                main: defaultColor,
                // light: "#6AB893",
                // dark: "#307454",
                contrastText: "#fff",
            },
        },
        typography: {
            fontFamily: font,
            fontWeightMedium: 500,
            fontWeightBold: 700,
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: defaultColor,
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        boxShadow: shadows.map(() => "none") as Shadows,
                    },
                },
            },
        },
    });
    return (
        <ThemeProvider theme={loginTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeDefaultComponent;
