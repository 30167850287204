import { useQuery } from "react-query";
import { ParamsGetSupplier, GetSupplierResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetSupplier) => {
    const apiGetSupplier = queryString.stringifyUrl({
        url: "api/suplier",
        query: {
            size: params.size,
            page: params.page,
            search: `${params.search}`,
        },
    });
    const { data } = await axios.get<GetSupplierResponse>(apiGetSupplier);
    return data.data;
};

export default function useSupplier(params: ParamsGetSupplier) {
    return useQuery<GetSupplierResponse["data"]>(["supplier", params], () =>
        handleRequest(params),
    );
}
