import * as React from "react";
import Drawer from "@mui/material/Drawer";
import useUkm from "../services/queries/useUkm";
import useUser from "../services/queries/useUser";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import BallotIcon from "@mui/icons-material/Ballot";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import AlertSnackbar from "../components/AlertSnackbar/AlertSnackbar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ModalKonfirmasiLogout from "../components/ModalKonfirmasiLogout/ModalKonfirmasiLogout";
import { Badge, Button } from "@mui/material";
import { useAlert } from "../context/alertContext";
import { PEMILIK_TOKO } from "../constants/common";
import { ParamsGetUkm } from "../constants/types";
import { useLogoutModal } from "../context/logoutModalContext";
import { Link, useLocation, useHistory } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import { getLocalUkmId, setLocalUkmId } from "../utils/handleChangeUkmId";
import {
    AppBar,
    Avatar,
    Box,
    Chip,
    Container,
    Divider,
    Grid,
    IconButton,
    Link as MaterialLink,
    ListItemButton,
    Stack,
    Toolbar,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CategoryIcon from "@mui/icons-material/Category";
import useUkmIsDueExpired from "../services/queries/useUkmIsDueExpired";
import { WhatsApp } from "@mui/icons-material";
import useNoWaAdmin from "../services/queries/useNoWaAdmin";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    children: React.ReactNode;
    // window?: () => Window;
}

export default function PageLayout(props: Props) {
    const { children } = props;
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const { data: user } = useUser();
    const initialParamsUkmIsDueExpired = React.useMemo(
        () => ({
            search: "",
            size: 5,
            page: 1,
            isDueExpired: 1,
        }),
        [],
    );
    const { data: ukmIsDueExpired } = useUkmIsDueExpired(
        initialParamsUkmIsDueExpired,
    );
    const { openModalLogout } = useLogoutModal();
    const { isOpenAlert, handleCloseAlert, words } = useAlert();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    // const [openSubmenu, setOpenSubmenu] = React.useState(true);
    const thisYear = new Date().getFullYear();
    const { data: noWaAdmin } = useNoWaAdmin();

    const location = useLocation();
    let history = useHistory();
    interface IOption {
        value: number;
        label: string;
    }

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] =
        React.useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm, status: statusUkm } = useUkm(paramsUkm);

    const optionsUkm = React.useMemo(
        () =>
            ukm?.content.map((ukms, index) => ({
                value: ukms.id,
                label: ukms.namaToko,
            })),
        [ukm?.content],
    );

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user]);

    const defaultSelected = optionsUkm?.find((li) => li.value === ukmId);
    const [selectUkm, setSelectUkm] = React.useState<IOption | undefined>(
        defaultSelected,
    );

    const drawerWidth = 190;

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukmId, ukm]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const customStyles: StylesConfig<any> = {
        control: (base: any, state: any) => ({
            ...base,
            background: "#FFFF",
            borderRadius: 8,
            borderColor: state.isFocused ? ukmData?.warna : "#d4d4d4",
            borderWidth: 1,
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                borderColor: state.isFocused ? ukmData?.warna : "black",
            },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? "#f5f5f5"
                    : isFocused
                    ? "#f5f5f5"
                    : undefined,
                color: isDisabled
                    ? "#ccc"
                    : isSelected
                    ? "black"
                        ? "black"
                        : "black"
                    : "black",
                cursor: isDisabled ? "not-allowed" : "default",

                ":active": {
                    ...styles[":active"],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? "black"
                            : "white"
                        : undefined,
                },
            };
        },
        input: (styles) => ({ ...styles, width: 150 }),
        placeholder: (styles) => ({ ...styles }),
        singleValue: (styles, { data }) => ({
            ...styles,
            borderBlockEndWidth: 0,
        }),
    };

    React.useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    history.listen(() => {
        setMobileOpen(false);
    });

    React.useEffect(() => {
        if (user?.userId) {
            if (ukmId) {
                const selectOption = optionsUkm?.find(
                    (li) => li.value === Number(ukmId),
                );
                selectOption && setSelectUkm(selectOption);
            }
        }
    }, [optionsUkm, ukmId, user?.userId]);

    const drawer = (
        // <div>
        <Stack
            marginY={2}
            display="flex"
            flex={1}
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Box width="100%">
                <Link to="/pengaturan">
                    <Stack
                        display="flex"
                        direction="row"
                        spacing={1}
                        sx={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginLeft: 1,
                        }}
                    >
                        <Avatar
                            sx={{
                                backgroundColor: "primary.main",
                                width: 42,
                                height: 42,
                            }}
                            alt={user?.namaLengkap}
                            src={user?.gambar ?? user?.namaLengkap}
                        />
                        <Grid container>
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                >
                                    {user?.namaLengkap}
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#76747C"
                                    >
                                        {ukmData?.namaToko}
                                    </Typography>
                                    <OpenInNewIcon
                                        fontSize="small"
                                        color="inherit"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Link>
                <Box my={1}>
                    <Divider />
                </Box>
                <ListItem
                    button
                    component={Link}
                    to="/transaksi"
                    selected={location.pathname === "/transaksi"}
                >
                    <ListItemIcon>
                        <ShoppingCartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Transaksi" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/stok"
                    selected={location.pathname === "/stok"}
                >
                    <ListItemIcon>
                        <AllInboxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Stok Penjualan" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/kategori"
                    selected={location.pathname === "/kategori"}
                >
                    <ListItemIcon>
                        <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kategori" />
                </ListItem>
                <ListItem
                    button
                    component={Link}
                    to="/laporan"
                    selected={location.pathname === "/laporan"}
                >
                    <ListItemIcon>
                        <StackedBarChartIcon />
                    </ListItemIcon>
                    <ListItemText primary="Laporan" />
                </ListItem>
            </Box>
            <Box width="100%">
                <ListItem disablePadding>
                    <ListItemButton onClick={openModalLogout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItemButton>
                </ListItem>
                {Number(user?.role.id) === PEMILIK_TOKO && (
                    <Stack
                        direction="column"
                        spacing={1}
                        marginX={2}
                        marginTop={2}
                    >
                        <Select
                            styles={customStyles}
                            value={selectUkm}
                            options={optionsUkm}
                            onChange={(e) => {
                                const selected = optionsUkm?.find(
                                    (li) => li.value === e?.value,
                                );
                                if (selected) {
                                    setSelectUkm(selected);
                                }
                            }}
                            isLoading={statusUkm === "loading"}
                            components={{
                                IndicatorSeparator: () => null,
                            }}
                            menuPlacement="auto"
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                if (selectUkm?.value) {
                                    user?.userId &&
                                        setLocalUkmId(
                                            user?.userId,
                                            selectUkm?.value,
                                        );
                                    window.location.reload();
                                }
                            }}
                        >
                            Simpan Perubahan
                        </Button>
                    </Stack>
                )}
                <Typography
                    marginTop={2}
                    marginLeft={2}
                    variant="body2"
                    color="#76747C"
                    component="div"
                >
                    Powered by{" "}
                    <Typography
                        variant="subtitle2"
                        fontWeight="bold"
                        display="inline"
                    >
                        Astramaya
                    </Typography>
                </Typography>
            </Box>
        </Stack>
    );

    const newDrawer = (
        // <div>
        <Stack
            display="flex"
            flex={1}
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
            marginY={4}
        >
            <Box width="100%">
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    marginBottom={7}
                >
                    <Link to="/pengaturan">
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="center"
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    <Box
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            position: "relative",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        <Box>
                                            <OpenInNewIcon
                                                sx={{
                                                    fontSize: "0.8rem",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                        "translate(-50%, -50%)",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                }
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: "primary.main",
                                        width: 60,
                                        height: 60,
                                    }}
                                    alt={user?.namaLengkap}
                                    src={user?.gambar ?? user?.namaLengkap}
                                />
                            </Badge>
                            <Typography>{user?.namaLengkap}</Typography>
                        </Stack>
                    </Link>
                </Box>
                <Stack direction="column" spacing="26px" alignItems="center">
                    <ListItem
                        button
                        component={Link}
                        to="/transaksi"
                        selected={location.pathname === "/transaksi"}
                        sx={{
                            width: 98,
                            height: 98,
                            padding: 0,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 1,
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="10px"
                            alignItems="center"
                        >
                            <ShoppingCartIcon
                                sx={{ fontSize: "36px", color: "#363845" }}
                            />
                            <Typography fontSize="12px" color="#363845">
                                Transaksi
                            </Typography>
                        </Stack>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/stok"
                        selected={location.pathname === "/stok"}
                        sx={{
                            width: 98,
                            height: 98,
                            padding: 0,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 1,
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="10px"
                            alignItems="center"
                        >
                            <AllInboxIcon
                                sx={{ fontSize: "36px", color: "#363845" }}
                            />
                            <Typography fontSize="12px" color="#363845">
                                Stok
                            </Typography>
                        </Stack>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/laporan"
                        selected={location.pathname === "/laporan"}
                        sx={{
                            width: 98,
                            height: 98,
                            padding: 0,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 1,
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="10px"
                            alignItems="center"
                        >
                            <StackedBarChartIcon
                                sx={{ fontSize: "36px", color: "#363845" }}
                            />
                            <Typography fontSize="12px" color="#363845">
                                Laporan
                            </Typography>
                        </Stack>
                    </ListItem>
                    <ListItem
                        button
                        component={Link}
                        to="/kategori"
                        selected={location.pathname === "/kategori"}
                        sx={{
                            width: 98,
                            height: 98,
                            padding: 0,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 1,
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing="10px"
                            alignItems="center"
                        >
                            <CategoryIcon
                                sx={{ fontSize: "36px", color: "#363845" }}
                            />
                            <Typography fontSize="12px" color="#363845">
                                Kategori
                            </Typography>
                        </Stack>
                    </ListItem>
                </Stack>
            </Box>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Box
                    display="flex"
                    onClick={openModalLogout}
                    marginBottom={3}
                    sx={{
                        width: 98,
                        height: 98,
                        padding: 0,
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 1,
                        textTransform: "none",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#f5f5f5",
                        },
                    }}
                >
                    <Stack
                        direction="column"
                        spacing="10px"
                        alignItems="center"
                    >
                        <LogoutIcon
                            sx={{ fontSize: "36px", color: "#363845" }}
                        />
                        <Typography fontSize="12px" color="#363845">
                            Keluar
                        </Typography>
                    </Stack>
                </Box>
                <Box>
                    <Typography fontSize="12px" color="#999999">
                        Powered by
                    </Typography>
                    <Typography
                        fontSize="15px"
                        color="#999999"
                        fontWeight="bold"
                    >
                        Astramaya
                    </Typography>
                </Box>
            </Box>
        </Stack>
    );

    const laptopDrawer = (
        // <div>
        <Stack flex={1} direction="column" alignItems="flex-start" marginY={2}>
            <Box width="100%">
                <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    marginBottom={2}
                >
                    <Link to="/pengaturan">
                        <Stack
                            direction="column"
                            spacing={1}
                            alignItems="center"
                        >
                            <Badge
                                overlap="circular"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                badgeContent={
                                    <Box
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            position: "relative",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        <Box>
                                            <OpenInNewIcon
                                                sx={{
                                                    fontSize: "0.8rem",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                        "translate(-50%, -50%)",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                }
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: "primary.main",
                                        width: 50,
                                        height: 50,
                                    }}
                                    alt={user?.namaLengkap}
                                    src={user?.gambar ?? user?.namaLengkap}
                                />
                            </Badge>
                            <Box>
                                <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    {user?.namaLengkap}
                                </Typography>
                                <Stack spacing={1} alignItems="center">
                                    <Typography
                                        textAlign="center"
                                        variant="caption"
                                        component="div"
                                    >
                                        {ukmData?.namaToko}
                                    </Typography>
                                    <Chip
                                        label={
                                            ukmData?.isPro === 1
                                                ? "PRO"
                                                : "FREE"
                                        }
                                        size="small"
                                        sx={{
                                            backgroundColor: "#45A779",
                                            color: "#fff",
                                        }}
                                    />
                                </Stack>
                            </Box>
                        </Stack>
                    </Link>
                </Box>
                <Stack direction="column" spacing="13px" alignItems="center">
                    {user?.aksesTransaksi === 1 && (
                        <ListItem
                            button
                            component={Link}
                            to="/transaksi"
                            selected={location.pathname === "/transaksi"}
                            sx={{
                                width: 80,
                                height: 80,
                                padding: 0,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="10px"
                                alignItems="center"
                            >
                                <ShoppingCartIcon
                                    sx={{ fontSize: "24px", color: "#363845" }}
                                />
                                <Typography fontSize="12px" color="#363845">
                                    Transaksi
                                </Typography>
                            </Stack>
                        </ListItem>
                    )}
                    {/* {user?.aksesTransaksi === 1 && (
                        <ListItem
                            button
                            component={Link}
                            to="/preorder"
                            selected={location.pathname === "/preorder"}
                            sx={{
                                width: 80,
                                height: 80,
                                padding: 0,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="10px"
                                alignItems="center"
                            >
                                <BallotIcon
                                    sx={{ fontSize: "24px", color: "#363845" }}
                                />
                                <Typography fontSize="12px" color="#363845">
                                    Pre Order
                                </Typography>
                            </Stack>
                        </ListItem>
                    )} */}
                    {user?.aksesStok === 1 && (
                        <ListItem
                            button
                            component={Link}
                            to="/stok"
                            selected={location.pathname === "/stok"}
                            sx={{
                                width: 80,
                                height: 80,
                                padding: 0,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="10px"
                                alignItems="center"
                            >
                                <AllInboxIcon
                                    sx={{ fontSize: "24px", color: "#363845" }}
                                />
                                <Typography fontSize="12px" color="#363845">
                                    Stok
                                </Typography>
                            </Stack>
                        </ListItem>
                    )}
                    {user?.aksesLaporan === 1 && (
                        <ListItem
                            button
                            component={Link}
                            to="/laporan"
                            selected={location.pathname === "/laporan"}
                            sx={{
                                width: 80,
                                height: 80,
                                padding: 0,
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing="10px"
                                alignItems="center"
                            >
                                <StackedBarChartIcon
                                    sx={{ fontSize: "24px", color: "#363845" }}
                                />
                                <Typography fontSize="12px" color="#363845">
                                    Laporan
                                </Typography>
                            </Stack>
                        </ListItem>
                    )}
                </Stack>
            </Box>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                flexGrow={1}
            />
            <Box padding={2}>
                <Button
                    href={`https://wa.me/send?phone=${noWaAdmin?.nomor}&text=Saya%20ingin%20bertanya%20mengenai%20Aplikasi%20BUMDes%20saya.`}
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    startIcon={<WhatsApp />}
                    color="whatsapp"
                    sx={{ textTransform: "none" }}
                >
                    Hubungi Kami
                </Button>
            </Box>
            <Box
                width="100%"
                display="flex"
                flexDirection="column"
                paddingX={2}
            >
                <Typography fontSize="12px" color="#999999">
                    Powered by
                </Typography>
                <Typography fontSize="15px" color="#999999" fontWeight="bold">
                    {`Astramaya © ${thisYear}`}
                </Typography>
            </Box>
        </Stack>
    );

    const container =
        window !== undefined ? () => window.document.body : undefined;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryUkmId = urlParams.get("redirect");

    return (
        <Box
            sx={{
                display: !!queryUkmId ? "none" : "flex",
                paddingTop: isPhoneScreen
                    ? "56px"
                    : isLaptopScreen
                    ? undefined
                    : "64px",
            }}
        >
            <AppBar
                sx={{
                    display: { xs: "block", lg: "none" },
                    width: { lg: `calc(100% - 190px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { lg: "none" } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {ukmData?.namaToko}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: 190 }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={() => setMobileOpen(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", lg: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {laptopDrawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {
                            xs: "none",
                            sm: "none",
                            md: "none",
                            lg: "block",
                        },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: 190,
                        },
                    }}
                    open
                >
                    {laptopDrawer}
                </Drawer>
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <AlertSnackbar
                    open={isOpenAlert}
                    onClose={handleCloseAlert}
                    words={words}
                />
                <ModalKonfirmasiLogout />
                <Container maxWidth="xl">{children}</Container>
            </Box>
        </Box>
    );
}
