import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link as RouterLink, useHistory } from "react-router-dom";
import LogoUlo from "../../components/LogoUlo/LogoUlo";
import ThemeDefaultComponent from "../../components/ThemeDefaultComponent/ThemeDefaultComponent";
import Stack from "@mui/material/Stack";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { LupaPasswordResponse } from "../../constants/types";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey2, defaultColor } from "../../constants/common";

interface ILupaPasswordProps {}

interface ILupaPasswordInput {
    email: string;
}

const schema = yup
    .object({
        email: yup
            .string()
            .email("Format email salah")
            .required("Kolom wajib diisi"),
    })
    .required();

const LupaPassword = (props: ILupaPasswordProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [token, setToken] = useState<string | null>();
    const [isHuman, setIsHuman] = useState(false);

    const history = useHistory();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryLoggedIn = urlParams.get("loggedIn");

    const initialValues = useMemo(
        () => ({
            email: "",
        }),
        [],
    );

    const {
        handleSubmit,
        setError,
        control,
        formState: { errors },
    } = useForm<ILupaPasswordInput>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const handleErrorReponse = (error: any) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError?.response) {
                const fieldError = serverError?.response?.data;
                if (fieldError?.errors) {
                    Object.keys(fieldError.errors).forEach((key) => {
                        const errorMessage = fieldError.errors[key];
                        setError(key as keyof typeof initialValues, {
                            type: "manual",
                            message: errorMessage[0],
                        });
                    });
                } else {
                    Swal.fire({
                        title: `${fieldError.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        }
    };

    const onSubmit = async (values: ILupaPasswordInput) => {
        console.log("Email: ", values);
        setIsButtonLoading(true);
        if (isHuman && token) {
            try {
                const formData = new FormData();
                formData.append("email", values.email);
                const { data } = await axios.post<LupaPasswordResponse>(
                    `/api/reqgantipass`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    // assignWords("Kata sandi berhasil diubah");
                    // openAlert();
                    setIsButtonLoading(false);
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    history.push(`/`);
                } else {
                    setIsButtonLoading(false);
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorReponse(error);
                console.error(error);
            }
        } else {
            setIsButtonLoading(false);
            Swal.fire({
                title: "Terjadi Kesalahan!",
                text: `Tolong centang box reCAPTCHA`,
                icon: "error",
                confirmButtonColor: "#45A779",
                customClass: {
                    container: "my-swal",
                },
            });
        }
    };

    return (
        <ThemeDefaultComponent>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100vh" }}
            >
                <Box
                    minHeight="50vh"
                    minWidth="50vw"
                    display="flex"
                    // flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="fit-content"
                    paddingX={isTabletScreen ? 4 : undefined}
                    paddingY={4}
                    border="1px solid rgba(0, 0, 0, 0.12)"
                    borderRadius="6px"
                    sx={{ backgroundColor: "#fff" }}
                >
                    <Stack spacing={3} alignItems="center">
                        <Link
                            href="https://bumdes-penjualan.noretest2.com/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <LogoUlo width="100px" />
                        </Link>
                        <Box>
                            <Typography
                                component="div"
                                textAlign="center"
                                variant="h6"
                                fontWeight="bold"
                            >
                                Lupa{" "}
                                <Typography
                                    variant="h6"
                                    fontWeight="bold"
                                    sx={{ color: defaultColor }}
                                    display="inline"
                                >
                                    Kata Sandi?
                                </Typography>
                            </Typography>
                            <Typography
                                marginTop={1}
                                variant="body2"
                                textAlign="center"
                                width="300px"
                            >
                                Masukkan email anda untuk mendapatkan tautan
                                atur ulang kata sandi.
                            </Typography>
                        </Box>
                        <form
                            style={{
                                width: "100%",
                                maxWidth: "600px",
                                minWidth: "300px",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Email
                                    </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.email)}
                                            >
                                                <OutlinedInput
                                                    id="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    autoFocus={isLaptopScreen}
                                                    placeholder="Contoh: budi@gmail.com"
                                                    size="small"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.email
                                                        ? errors.email.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                        rules={{ required: "Email required" }}
                                    />
                                </Grid>
                            </Grid>
                            <Box
                                marginTop={1}
                                display="flex"
                                justifyContent="center"
                            >
                                <ReCAPTCHA
                                    sitekey={captchaSiteKey2}
                                    onChange={(value) => {
                                        setToken(value);
                                        setIsHuman(true);
                                    }}
                                    onExpired={() => {
                                        setToken(null);
                                        setIsHuman(false);
                                    }}
                                    hl="id"
                                />
                            </Box>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                type="submit"
                                fullWidth
                                size="medium"
                                variant="contained"
                                sx={{ marginTop: 3 }}
                            >
                                Kirim email
                            </LoadingButton>
                        </form>
                        <Box
                            display="flex"
                            justifyContent="center"
                            marginTop={3}
                            sx={{ width: "100%" }}
                        >
                            {!!queryLoggedIn ? (
                                <Link
                                    component={RouterLink}
                                    to="/pengaturan"
                                    variant="body2"
                                    underline="hover"
                                    sx={{ cursor: "pointer" }}
                                >
                                    Kembali ke Pengaturan
                                </Link>
                            ) : (
                                <Link
                                    component={RouterLink}
                                    to="/"
                                    variant="body2"
                                    underline="hover"
                                    sx={{ cursor: "pointer" }}
                                >
                                    Kembali ke Login
                                </Link>
                            )}
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </ThemeDefaultComponent>
    );
};

export default LupaPassword;
