import {
    Button,
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@mui/material";
import React, { useCallback } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import { useHapusUkmModal } from "../../context/hapusUkmModalContext";

interface IModalDeleteUkmProps {
    refetch: () => void;
}

const ModalDeleteUkm = ({ refetch }: IModalDeleteUkmProps) => {
    const { isOpenModalHapusUkm, closeModalHapusUkm, namaUkm, idUkm } =
        useHapusUkmModal();

    const handleDelete = async (id: string | number | undefined) => {
        try {
            await axios.delete(`/api/ukm/${id}`);
            refetch();
            closeModalHapusUkm();
        } catch (error) {
            // console.error(error);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback((error) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError.response) {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `${serverError.response.data.message}`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
                closeModalHapusUkm();
            } else {
                Swal.fire({
                    title: "Terjadi Kesalahan!",
                    text: `Telah Terjadi Kesalahan`,
                    icon: "error",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });
                closeModalHapusUkm();
            }
        }
    }, []);

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalHapusUkm}
            onClose={closeModalHapusUkm}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalHapusUkm}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus BUMDes
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    BUMDes {namaUkm} akan dihapus secara permanen. Yakin ingin
                    menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => handleDelete(idUkm)}
                    color="error"
                >
                    Hapus
                </Button>
                <Button
                    variant="contained"
                    onClick={closeModalHapusUkm}
                    color="error"
                >
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeleteUkm;
