import React from "react";
import UloWhite from "../../assets/logo/bumdes-logo.png";

interface ILogoUloProps {
    width?: string | number | undefined;
    height?: string | number | undefined;
}

const LogoUloWhite = ({ width, height }: ILogoUloProps) => {
    return (
        <img
            src={UloWhite}
            alt="Logo Astramaya"
            width={width}
            height={height}
        />
    );
};

export default LogoUloWhite;
