import React, { useCallback, useState } from "react";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import defaultAxios, { AxiosError } from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { useTransaksi } from "../../context/transaksiContext";

interface IModalDeletePreOrderProps {
    isOpenModalHapusPreOrder: boolean;
    closeModalHapusPreOrder: () => void;
    namaPreOrder: string;
    idPreOrder?: string | number;
}

const ModalDeletePreOrder = ({
    isOpenModalHapusPreOrder,
    closeModalHapusPreOrder,
    namaPreOrder,
    idPreOrder,
}: IModalDeletePreOrderProps) => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    const handleDelete = async (id: string | number | undefined) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.delete(`/api/transaksidelpo/${id}`);
            if (data.code === 200) {
                Swal.fire({
                    title: namaPreOrder + " berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setIsButtonLoading(false);
                setFetchingItems();
            }
            closeModalHapusPreOrder();
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error: any) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalHapusPreOrder();
                } else {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalHapusPreOrder();
                }
            }
        },
        [closeModalHapusPreOrder],
    );

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalHapusPreOrder}
            onClose={() => {}}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalHapusPreOrder}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus Pre Order
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {namaPreOrder} akan dihapus secara permanen. Yakin ingin
                    menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="outlined"
                    onClick={() => handleDelete(idPreOrder)}
                    color="error"
                >
                    Hapus
                </LoadingButton>
                <Button
                    variant="contained"
                    onClick={closeModalHapusPreOrder}
                    color="error"
                >
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeletePreOrder;
