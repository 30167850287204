import React from "react";
import OpenSans from "../../assets/fonts/OpenSans-Regular.ttf";
import UbuntuMono from "../../assets/fonts/UbuntuMono-Regular.ttf";
import {
    StyleSheet,
    Font,
    Page,
    Document,
    Image,
    View,
    Text,
} from "@react-pdf/renderer";

Font.register({
    family: "Open-Sans",
    format: "truetype",
    src: OpenSans,
});

Font.register({
    family: "Ubuntu-Mono",
    format: "truetype",
    src: UbuntuMono,
});

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        backgroundColor: "#FFF",
        paddingVertical: "1mm",
        // paddingBottom: 0,
        paddingLeft: "1mm",
        // paddingRight: 10,
        alignItems: "center",
        justifyContent: "center",
    },
    wrapper: {
        height: "10mm",
        width: "35mm",
        display: "flex",
        flexDirection: "column",
        marginHorizontal: "2.3mm",
        marginTop: "4.6mm",
        padding: 2,
        // border: "1px solid #bdbdbd",
        // borderStyle: "solid",
        // borderColor: "#bdbdbd",
        // borderWidth: "1px",
        // borderTopLeftRadius: "4px",
        // borderTopRightRadius: "4px",
        // borderBottomRightRadius: "4px",
        // borderBottomLeftRadius: "4px",
    },
    barcode: {
        width: "auto",
    },
    harga: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        // textAlign: "center",
        fontWeight: "bold",
    },
    nama: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    sku: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
    },
    textContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
    },
    textParent: {
        padding: 2,
    },
});

const styles2 = StyleSheet.create({
    page: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        backgroundColor: "#FFF",
        paddingVertical: "2mm",
        // paddingBottom: 0,
        paddingLeft: "5mm",
        // paddingRight: 10,
        // alignItems: "center",
        // justifyContent: "center",
    },
    wrapper: {
        height: "10mm",
        width: "35mm",
        display: "flex",
        flexDirection: "column",
        marginHorizontal: "2.4mm",
        marginVertical: "2.3mm",
        padding: 2,
        // border: "1px solid #bdbdbd",
        // borderStyle: "solid",
        // borderColor: "#bdbdbd",
        // borderWidth: "1px",
        // borderTopLeftRadius: "4px",
        // borderTopRightRadius: "4px",
        // borderBottomRightRadius: "4px",
        // borderBottomLeftRadius: "4px",
    },
    barcode: {
        width: "auto",
    },
    harga: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        // textAlign: "center",
        fontWeight: "bold",
    },
    nama: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
        textTransform: "uppercase",
    },
    sku: {
        fontSize: 6,
        fontFamily: "Ubuntu-Mono",
        textAlign: "center",
        fontWeight: "bold",
    },
    textContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
    },
    textParent: {
        padding: 2,
    },
});

interface IPrintBarcodeProps {
    jumlahCetak: number;
    valueBarcode: string;
    namaBarang: string;
    hargaBarang: string;
    sku: string;
    isLabel: boolean;
}

const PrintBarcode = ({
    jumlahCetak,
    valueBarcode,
    namaBarang,
    hargaBarang,
    sku,
    isLabel,
}: IPrintBarcodeProps) => {
    var JsBarcode = require("jsbarcode");

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, `${valueBarcode}`, {
        height: 70,
        displayValue: false,
    });
    const barcode = canvas.toDataURL();

    return (
        <Document>
            {isLabel ? (
                <Page
                    // size={{ width: "20cm", height: "16.5cm" }}
                    size="A4"
                    style={styles2.page}
                >
                    {Array.from(Array(jumlahCetak)).map((li, idx) => (
                        <View
                            key={String(idx)}
                            style={styles2.wrapper}
                            wrap={false}
                        >
                            <Image style={styles2.barcode} src={barcode} />
                            <View style={styles2.textContainer}>
                                <View>
                                    <Text style={styles2.sku}>{sku}</Text>
                                </View>
                            </View>
                            <View
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    padding: 2,
                                }}
                            >
                                <View>
                                    <Text style={styles2.nama}>
                                        {namaBarang}
                                    </Text>
                                </View>
                                <View>
                                    <Text style={styles2.harga}>
                                        {hargaBarang}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    ))}
                </Page>
            ) : (
                <Page size="A4" style={styles.page}>
                    {Array.from(Array(jumlahCetak)).map((li, idx) => (
                        <View
                            key={String(idx)}
                            style={styles.wrapper}
                            wrap={false}
                        >
                            <Image style={styles.barcode} src={barcode} />
                            <View style={styles.textContainer}>
                                <Text style={styles.sku}>{sku}</Text>
                                <Text style={styles.harga}>{hargaBarang}</Text>
                                <Text style={styles.nama}>{namaBarang}</Text>
                            </View>
                        </View>
                    ))}
                </Page>
            )}
        </Document>
    );
};

export default PrintBarcode;
