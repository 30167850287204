import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import PrintStrukTableHeader from "./PrintStrukTableHeader";
import PrintStrukA4TableRow from "./PrintStrukA4TableRow";
import PrintStrukA4Diskon from "./PrintStrukA4Diskon";
import PrintStrukA4Pajak from "./PrintStrukA4Pajak";
import PrintStrukA4Tunai from "./PrintStrukA4Tunai";
import PrintStrukA4Kembalian from "./PrintStrukA4Kembalian";
import PrintStrukA4Subtotal from "./PrintStrukA4Subtotal";
import PrintStrukA4Total from "./PrintStrukA4Total";
import PrintStrukA4CustomAmount from "./PrintStrukA4CustomAmount";
import PrintStrukA4Ongkir from "./PrintStrukA4Ongkir";

interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    jumlahStok?: number | undefined;
}

interface IPrintStrukA4TableProps {
    cart?: ICart[];
    diskon?: number;
    pajak?: number;
    tunai?: number;
    kembalian?: number;
    namaMetode?: string | null;
    hargaCustomAmount?: string | null;
    hargaOngkir?: string | null;
    total: number;
}

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
    },
});

const PrintStrukA4Table = ({
    cart,
    diskon,
    pajak,
    tunai,
    kembalian,
    namaMetode,
    total,
    hargaCustomAmount,
    hargaOngkir,
}: IPrintStrukA4TableProps) => {
    return (
        <View style={styles.tableContainer}>
            <PrintStrukTableHeader />
            <PrintStrukA4TableRow cart={cart} />
            <PrintStrukA4Subtotal cart={cart} />
            {hargaCustomAmount ? (
                <PrintStrukA4CustomAmount
                    hargaCustomAmount={hargaCustomAmount}
                />
            ) : (
                <View></View>
            )}
            {hargaOngkir ? (
                <PrintStrukA4Ongkir hargaOngkir={hargaOngkir} />
            ) : (
                <View></View>
            )}
            {diskon ? <PrintStrukA4Diskon diskon={diskon} /> : <View></View>}
            {pajak ? <PrintStrukA4Pajak pajak={pajak} /> : <View></View>}
            <PrintStrukA4Total total={total} />
            {tunai ? (
                <PrintStrukA4Tunai tunai={tunai} namaMetode={namaMetode} />
            ) : (
                <View></View>
            )}
            {kembalian ? (
                <PrintStrukA4Kembalian kembalian={kembalian} />
            ) : (
                <View></View>
            )}
        </View>
    );
};

export default PrintStrukA4Table;
