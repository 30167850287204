import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    TextField,
    Grid,
    useMediaQuery,
    Autocomplete,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "../../services/axios";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { useKategoriModal } from "../../context/kategoriModalContext";
import EditIcon from "@mui/icons-material/Edit";
import {
    EditKategoriBody,
    ErrorFieldResponse,
    ParamsGetCoa,
} from "../../constants/types";
// import useUser from "../../services/queries/useUser";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import { useKategoriModal } from "../../context/kategoriModalContext";
import useCoa from "../../services/queries/useCoa";

interface IModalEditKategoriProps {
    refetch: () => void;
}

// interface IValuesKategori {
//   id: number | string | undefined;
//   nama: string;
// }

const schema = yup.object({
    namaKategori: yup.string().required("Kolom wajib diisi"),
    coaKategori: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            noAkun: yup.string().required("Kolom wajib diisi"),
            namaAkun: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
});

const ModalEditKategori = ({ refetch }: IModalEditKategoriProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const {
        isOpenModalEditKategori,
        closeModalEditKategori,
        namaKategori,
        idKategori,
        coaKategori,
    } = useEditKategoriModal();
    const { openModalKategori } = useKategoriModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const initialParamsCoa = {
        size: 100,
        page: 1,
        search: "",
        kategori: "113",
    };
    const [paramsCoa, setParamsCoa] = useState<ParamsGetCoa>(initialParamsCoa);
    const { data: coa, isLoading: isLoadingCoa } = useCoa(paramsCoa);
    const mappedCoa = coa?.content
        .filter((c) => c.header !== 1)
        .map((li) => ({
            id: li.id,
            noAkun: li.noAkun,
            namaAkun: li.namaAkun,
        }));
    // const { data: user } = useUser();

    const initialValues = useMemo(
        () => ({
            // id: idKategori,
            namaKategori: namaKategori,
            coaKategori: coaKategori,
        }),
        [coaKategori, namaKategori],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        getValues,
        setError,
    } = useForm<EditKategoriBody>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: EditKategoriBody) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.put(`/api/kategori/${idKategori}`, {
                namaKategori: values.namaKategori,
                coa: values.coaKategori.id,
            });
            if (data.code === 200) {
                // assignWords("Kategori berhasil diubah menjadi " + getValues("namaKategori"));
                // openAlert();
                Swal.fire({
                    title:
                        "Kategori berhasil diubah menjadi " +
                        getValues("namaKategori"),
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
            setIsButtonLoading(false);
            refetch();
            closeModalEditKategori();
            openModalKategori();
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            setIsButtonLoading(false);
            console.error(error);
            // console.log(data.nama);
        }
    };

    useEffect(() => {
        if (isOpenModalEditKategori) {
            if (namaKategori && idKategori) {
                reset(initialValues);
                // console.log(initialValues);
            }
        }
    }, [
        idKategori,
        initialValues,
        isOpenModalEditKategori,
        namaKategori,
        reset,
    ]);

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            open={isOpenModalEditKategori}
            onClose={() => {
                closeModalEditKategori();
                openModalKategori();
            }}
            scroll="body"
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            closeModalEditKategori();
                            openModalKategori();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Edit Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Controller
                                name="namaKategori"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="nama"
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        error={Boolean(errors.namaKategori)}
                                        helperText={
                                            errors.namaKategori
                                                ? errors.namaKategori.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="coaKategori"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        loading={isLoadingCoa}
                                        options={mappedCoa ?? []}
                                        getOptionLabel={(option) => {
                                            return `(${option.noAkun}) - ${option.namaAkun}`;
                                        }}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        size="small"
                                        value={field.value}
                                        onInputChange={(e) => {
                                            if (e) {
                                                const element =
                                                    e.target as HTMLInputElement;
                                                setParamsCoa((prev) => ({
                                                    ...prev,
                                                    search: element.value,
                                                }));
                                            } else {
                                                return;
                                            }
                                        }}
                                        onChange={(
                                            _event,
                                            newCoa: {
                                                id: number;
                                                noAkun: string;
                                                namaAkun: string;
                                            } | null,
                                        ) => {
                                            field.onChange(newCoa);
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih COA"
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error &&
                                                    "Pilih satu COA"
                                                }
                                            />
                                        )}
                                        ListboxProps={{
                                            style: {
                                                maxHeight: "80px",
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        {!isLaptopScreen && <Grid item xs sm={8}></Grid>}
                        <Grid item xs={12} sm={4} mt={4}>
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                variant="contained"
                                fullWidth
                                size="large"
                                type="submit"
                                onClick={handleSubmit(onSubmit)}
                                sx={{ top: 8, height: "40px" }}
                            >
                                {isPhoneScreen ? <EditIcon /> : "Simpan"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ModalEditKategori;
