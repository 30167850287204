import {
    Card,
    CardContent,
    CardActionArea,
    Box,
    Avatar,
    Stack,
    Typography,
    Badge,
} from "@mui/material";
import React from "react";
import { DetailBundling } from "../../constants/types";
import avatarAlt from "../../utils/avatarAlt";
import toRupiah from "../../utils/toRupiah";
import StarIcon from "@mui/icons-material/Star";
import { useTransaksi } from "../../context/transaksiContext";
import toRibuan from "../../utils/toRibuan";
import truncate from "../../utils/truncate";

interface IMenuBarangProps {
    id: number;
    namaBarang: string;
    deskripsi?: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    detailBundling: DetailBundling[] | [];
    favorite: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number | string;
        keterangan: string | null;
    }[];
}

const MenuBarang = ({
    id,
    namaBarang,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    detailBundling,
    favorite,
    hpp,
    multipleHarga,
}: IMenuBarangProps) => {
    const { addToCart, cart } = useTransaksi();

    return (
        <Card
            variant="outlined"
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                borderColor:
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok < 0 && jumlahStok !== null)
                        ? "primary.main"
                        : undefined,
                borderWidth:
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok < 0 && jumlahStok !== null)
                        ? "medium"
                        : undefined,
            }}
        >
            <CardActionArea
                disabled={
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok <= 0 && jumlahStok !== null)
                }
                onClick={() => {
                    addToCart({
                        id,
                        namaBarang,
                        harga,
                        url,
                        qty: 1,
                        sku,
                        jumlahStok,
                        detailBundling,
                        hpp,
                        faktur: [],
                        multipleHarga,
                    });
                }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <CardContent sx={{ paddingBottom: 0, width: "100%" }}>
                    <Typography
                        fontSize={12}
                        color={
                            jumlahStok < 5 && jumlahStok !== null
                                ? "#ef4444"
                                : "#76747C"
                        }
                        fontWeight={
                            jumlahStok < 5 && jumlahStok !== null ? 700 : 500
                        }
                    >
                        {detailBundling.length > 0 && jumlahStok === null
                            ? "Otomatis"
                            : jumlahStok === null
                            ? "Tanpa stok"
                            : jumlahStok > 5
                            ? "Tersedia " + toRibuan(jumlahStok) + " barang"
                            : jumlahStok <= 5 && jumlahStok > 0
                            ? "Barang tersisa " + toRibuan(jumlahStok)
                            : "Barang telah habis"}
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <Badge
                            overlap="circular"
                            badgeContent={
                                favorite > 0 ? (
                                    <Box
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            position: "relative",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        <Box>
                                            <StarIcon
                                                htmlColor="#fbbf24"
                                                sx={{
                                                    fontSize: "1.3rem",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                        "translate(-50%, -50%)",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ) : undefined
                            }
                            sx={{ marginY: 3 }}
                        >
                            <Avatar
                                alt={namaBarang}
                                src={url === null ? "" : url}
                                sx={{
                                    bgcolor: "primary.main",
                                    width: 70,
                                    height: 70,
                                }}
                            >
                                {avatarAlt(namaBarang)}
                            </Avatar>
                        </Badge>
                    </Box>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        // marginBottom={2}
                        justifyContent="center"
                    >
                        <Typography
                            variant="h6"
                            textAlign="center"
                            fontWeight="bold"
                            fontSize="16px"
                        >
                            {namaBarang}
                        </Typography>
                    </Stack>
                    {deskripsi ? (
                        <Typography
                            variant="body2"
                            textAlign="center"
                            fontSize="12px"
                            color="#76747C"
                            sx={{ marginBottom: 2 }}
                        >
                            {truncate(deskripsi, 50)}
                        </Typography>
                    ) : undefined}
                </CardContent>
                {/* <CardContent sx={{ marginTop: "auto", bottom: "16px" }}> */}
                <Box display="flex" flexDirection="column" marginBottom={2}>
                    {multipleHarga.length > 0 ? (
                        multipleHarga.map((li, idx) => (
                            <Box
                                key={idx.toString()}
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={0.5}
                            >
                                {/* <Circle
                                            sx={{ width: "8px", height: "8px" }}
                                        /> */}
                                {li.keterangan === "-''-" ||
                                li.keterangan === null ? (
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontWeight="bold"
                                        fontSize="18px"
                                        textAlign="center"
                                    >
                                        {toRupiah(Number(li.harga))}
                                    </Typography>
                                ) : (
                                    <Typography
                                        variant="h6"
                                        color="primary"
                                        fontWeight="bold"
                                        fontSize="18px"
                                        textAlign="center"
                                    >
                                        {li.keterangan} -{" "}
                                        {toRupiah(Number(li.harga))}
                                    </Typography>
                                )}
                            </Box>
                        ))
                    ) : (
                        <Typography
                            variant="h6"
                            color="primary"
                            fontWeight="bold"
                            fontSize="18px"
                            textAlign="center"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            {toRupiah(harga)}
                        </Typography>
                    )}
                </Box>
                {/* </CardContent> */}
            </CardActionArea>
        </Card>
    );
};

export default MenuBarang;
