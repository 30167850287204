import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
// import { ThemeProvider } from "@mui/styles";
import React, { useEffect } from "react";
import InfoToko from "../../components/InfoToko/InfoToko";
import CardInfoUser from "../../components/InfoAkun/CardInfoUser";
import CardChangePassword from "../../components/InfoAkun/CardChangePassword";
import LogoutIcon from "@mui/icons-material/Logout";
import Spinner from "../../components/Spinner/Spinner";
import useUser from "../../services/queries/useUser";
import { useLogoutModal } from "../../context/logoutModalContext";
import FAQ from "../../components/FAQ/FAQ";

interface IPengaturanProps {}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Pengaturan = (props: IPengaturanProps) => {
    // const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { openModalLogout } = useLogoutModal();
    const { status: statusUser, data: user } = useUser();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        console.log(user);
    }, [user]);

    if (statusUser === "loading") {
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height="100vh"
                position="relative"
            >
                <Spinner />
            </Stack>
        );
    }

    return (
        <React.Fragment>
            {/* <ThemeProvider theme={theme}> */}
            <Typography fontWeight="bold" variant="h5" sx={{ marginY: 2 }}>
                Pengaturan
            </Typography>
            <Box>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                    >
                        <Tab label="Informasi Akun" {...a11yProps(0)} />
                        <Tab
                            label="Informasi BUMDes"
                            {...a11yProps(1)}
                            sx={{
                                display:
                                    user?.role.id !== "10" ? "none" : undefined,
                            }}
                        />

                        {/* <Tab label="Pusat Bantuan" {...a11yProps(2)} /> */}
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid container spacing={3}>
                        <CardInfoUser />
                        <CardChangePassword />
                        <Stack direction="row" mt={3} ml={3} spacing={2}>
                            {user?.role.id === "10" && (
                                <Button
                                    variant="outlined"
                                    href={
                                        process.env.NODE_ENV === "production"
                                            ? `https://bumdes-manager.noretest2.com/`
                                            : `http://localhost:3000`
                                    }
                                    rel="noreferrer"
                                >
                                    Ke Bendahara
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="error"
                                onClick={openModalLogout}
                                startIcon={<LogoutIcon />}
                            >
                                Keluar
                            </Button>
                        </Stack>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <InfoToko />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <FAQ />
                </TabPanel>
            </Box>
            {/* </ThemeProvider> */}
        </React.Fragment>
    );
};

export default Pengaturan;
