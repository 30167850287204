import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

interface IPrintStrukA4TotalProps {
    total: number;
}

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        borderLeftColor: "#fff",
        borderLeftWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        fontSize: 8,
    },
    description: {
        width: "70%",
        textAlign: "right",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotalText: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 18,
    },
    subtotal: {
        width: "15%",
        textAlign: "right",
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        paddingHorizontal: 8,
        height: 18,
        backgroundColor: "#d2d9ec",
    },
});

const PrintStrukA4Total = ({ total }: IPrintStrukA4TotalProps) => {
    return (
        <View style={styles.row}>
            <Text style={styles.description}> </Text>
            <Text style={styles.subtotalText}>Total</Text>
            <Text style={styles.subtotal}>{toRibuan(Number(total))}</Text>
        </View>
    );
};

export default PrintStrukA4Total;
