import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface State {
    closeModalPrintBarcode: () => void;
    sku: string | undefined;
    isOpenModalPrintBarcode: boolean;
    openModalPrintBarcode: (
        sku: string,
        namaBarang: string,
        hargaBarang: string,
    ) => void;
    namaBarang: string | undefined;
    hargaBarang: string | undefined;
    isLabel: boolean;
    handleChangeLabel: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IHapusUkmModalContext {
    children: ReactNode;
}

const PrintBarcodeModalContext = createContext<State | undefined>(undefined);

const PrintBarcodeModalProvider = ({ children }: IHapusUkmModalContext) => {
    const [isOpenModalPrintBarcode, setIsOpenModalPrintBarcode] =
        useState(false);
    const [sku, setSku] = useState<string | undefined>();
    const [namaBarang, setNamaBarang] = useState<string | undefined>();
    const [hargaBarang, setHargaBarang] = useState<string | undefined>();
    const [isLabel, setIsLabel] = useState(true);

    const handleChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLabel(event.target.checked);
    };

    const openModalPrintBarcode = useCallback(
        (sku: string, namaBarang: string, hargaBarang: string) => {
            setIsOpenModalPrintBarcode(true);
            setSku(sku);
            setNamaBarang(namaBarang);
            setHargaBarang(hargaBarang);
        },
        [],
    );

    const closeModalPrintBarcode = useCallback(() => {
        setIsOpenModalPrintBarcode(false);
    }, []);

    const value = useMemo(
        () => ({
            closeModalPrintBarcode,
            sku,
            isOpenModalPrintBarcode,
            openModalPrintBarcode,
            namaBarang,
            hargaBarang,
            isLabel,
            handleChangeLabel,
        }),
        [
            closeModalPrintBarcode,
            sku,
            isOpenModalPrintBarcode,
            openModalPrintBarcode,
            namaBarang,
            hargaBarang,
            isLabel,
        ],
    );
    return (
        <PrintBarcodeModalContext.Provider value={value}>
            {children}
        </PrintBarcodeModalContext.Provider>
    );
};

const usePrintBarcodeModal = () => {
    const context = React.useContext(PrintBarcodeModalContext);
    if (context === undefined) {
        throw new Error(
            "usePrintBarcodeModal must be used within a PrintBarcodeModalProvider",
        );
    }
    return context;
};

export {
    PrintBarcodeModalContext,
    PrintBarcodeModalProvider,
    usePrintBarcodeModal,
};
