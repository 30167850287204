import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import { DaftarItemListInvoice, TipeStruk } from "../../constants/types";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import toRupiah from "../../utils/toRupiah";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

interface IModalDetailInvoiceProps {
    fullScreen: boolean;
    isOpenModalDetailInvoice: boolean;
    statusDetailInvoice: "idle" | "error" | "loading" | "success";
    closeModalDetailInvoice: () => void;
    tanggalTransaksi: string;
    nomorInvoice: string;
    daftarItem: DaftarItemListInvoice[];
    totalHarga: number;
    diskon: number;
    pajak: number;
    totalPembayaran: number;
    uangYangDibayar: number;
    kembalian: number;
    kasir: string;
    sales: string | null;
    namaMetode: string | null;
    keterangan: string | null;
    encrypt: string | null;
    noInvoice: string;
    ongkir: string | null;
    ketOngkir: string | null;
    customAmount: string | null;
    ketCustomAmount: string | null;
    jatuhTempo: string | null;
    metodePelanggan: string | null;
    isPreOrder?: boolean;
}

const ModalDetailInvoice = ({
    fullScreen,
    isOpenModalDetailInvoice,
    statusDetailInvoice,
    closeModalDetailInvoice,
    tanggalTransaksi,
    nomorInvoice,
    daftarItem,
    totalHarga,
    diskon,
    pajak,
    totalPembayaran,
    uangYangDibayar,
    kembalian,
    kasir,
    sales,
    namaMetode,
    keterangan,
    encrypt,
    noInvoice,
    ongkir,
    ketOngkir,
    customAmount,
    ketCustomAmount,
    jatuhTempo,
    metodePelanggan,
    isPreOrder,
}: IModalDetailInvoiceProps) => {
    const { data: user } = useUser();
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const newCart = daftarItem.map((items, index) => ({
        id: index,
        namaBarang: String(items.namaItem),
        qty: Number(items.jumlahItem),
        harga: Number(items.hargaSatuan),
    }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukLaporan") ?? TipeStruk.Mobile,
    );

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukLaporan", newTipeStruk);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailInvoice && statusDetailInvoice === "success"}
            onClose={() => {
                setKirimEmail(false);
                setEmail("");
                closeModalDetailInvoice();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Detail Invoice
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Tanggal
                        </Typography>
                        <Typography variant="subtitle2">
                            {/* Hide waktu pada tanggal transaksi */}
                            {tanggalTransaksi}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            No. Invoice
                        </Typography>
                        <Typography variant="subtitle2">
                            {nomorInvoice}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography variant="subtitle2" color="#A1A1AA">
                            Metode
                        </Typography>
                        <Typography variant="subtitle2">
                            {isPreOrder ? "Pre Order" : namaMetode ?? "Tunai"}
                        </Typography>
                    </Grid>
                </Grid>
                <Box my={2}>
                    <Divider />
                </Box>
                <Stack spacing={1}>
                    {daftarItem.map((items, index) => (
                        <Stack direction="row" key={index}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {items.namaItem}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        {toRupiah(items.hargaSatuan)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        {items.jumlahItem}x
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            items.hargaSatuan *
                                                items.jumlahItem,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    ))}
                    {customAmount && (
                        <Stack direction="row">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Custom Amount
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        {ketCustomAmount}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        textAlign="end"
                                    >
                                        {toRupiah(Number(customAmount))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    )}
                    {ongkir && (
                        <Stack direction="row">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                    >
                                        Ongkos Kirim
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        {ketOngkir}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight="bold"
                                        textAlign="end"
                                    >
                                        {toRupiah(Number(ongkir))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    )}
                </Stack>
                <Box my={2}>
                    <Divider />
                </Box>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Subtotal
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(totalHarga)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                {customAmount && (
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Custom Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(Number(customAmount))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
                {ongkir && (
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Ongkos Kirim
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {toRupiah(Number(ongkir))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(diskon)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Pajak
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(pajak)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    my={2}
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle1">Total</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(totalPembayaran)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Uang yang Dibayar
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(uangYangDibayar)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                <Stack
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item xs={3}>
                            <Typography variant="subtitle2" color="#A1A1AA">
                                Kembalian
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="subtitle1"
                                fontWeight="bold"
                                textAlign="end"
                            >
                                {toRupiah(kembalian)}
                            </Typography>
                        </Grid>
                    </Grid>
                </Stack>
                {metodePelanggan && (
                    <Stack
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid
                            container
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid item xs={3}>
                                <Typography variant="subtitle2" color="#A1A1AA">
                                    Pelanggan
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    textAlign="end"
                                >
                                    {metodePelanggan}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                )}
                <Stack
                    mb={5}
                    direction="row"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid
                        container
                        alignItems="center"
                        // justifyContent="flex-end"
                    >
                        <Grid item xs={3}></Grid>
                        <Grid item xs={3}>
                            <Typography
                                variant="subtitle2"
                                color="#A1A1AA"
                                mb={1}
                            >
                                Keterangan
                            </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={9}>
                            <TextField
                                disabled
                                multiline
                                rows={2}
                                fullWidth
                                value={keterangan}
                                sx={{
                                    "& .MuiOutlinedInput-input.Mui-disabled": {
                                        color: "rgba(0, 0, 0, 0.87)",
                                        WebkitTextFillColor:
                                            "rgba(0, 0, 0, 0.87)",
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>Pilih Tipe Struk</Typography>
                    <ToggleButtonGroup
                        value={tipeStruk}
                        exclusive
                        size="small"
                        onChange={handleTipeStruk}
                    >
                        <ToggleButton
                            value={TipeStruk.Mobile}
                            sx={{
                                display: "flex",
                                paddingX: 2,
                            }}
                        >
                            <b>A8</b>
                        </ToggleButton>
                        <ToggleButton
                            value={TipeStruk.PC}
                            sx={{
                                display: "flex",
                                paddingX: 2,
                            }}
                        >
                            <b>A4</b>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
                <Box
                    display="flex"
                    // justifyContent="flex-start"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={kirimEmail}
                                onChange={(_, checked) => {
                                    handleKirimEmail(checked);
                                }}
                            />
                        }
                        label="Kirim struk"
                        sx={{
                            height: "fit-content",
                        }}
                    />
                </Box>
                {kirimEmail && (
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        marginBottom={2}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={email}
                                size="small"
                                type="email"
                                fullWidth
                                placeholder="8316352725"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +62
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => setEmail(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={email.length <= 0}
                                variant="outlined"
                                onClick={() => {
                                    setKirimEmail(false);
                                    setEmail("");
                                    closeModalDetailInvoice();
                                }}
                                href={`https://wa.me/send?phone=62${email}&text=Struk%20pembayaran%20Aplikasi%20BUMDes%20${nomorInvoice}%20${
                                    process.env.NODE_ENV === "production"
                                        ? `https://bumdes-penjualan.noretest2.com`
                                        : `http://localhost:3001`
                                }/struk/${encrypt}`}
                                target="_blank"
                                rel="noreferrer"
                                fullWidth
                            >
                                Kirim struk ke WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Stack
                    width="100%"
                    direction="column"
                    alignItems="center"
                    spacing={1}
                >
                    <Button
                        variant="text"
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                        fullWidth
                        sx={{ display: { xs: "block", md: "none" } }}
                    >
                        Kembali
                    </Button>
                    <Box width="100%">
                        {ukmData &&
                            (tipeStruk === TipeStruk.Mobile ? (
                                <BlobProvider
                                    document={
                                        <PrintStrukPdf
                                            namaToko={ukmData.namaToko}
                                            alamatToko={ukmData.alamatToko}
                                            telpToko={ukmData.noTelp}
                                            gambarToko={
                                                ukmData?.gambarTokoBW === null
                                                    ? undefined
                                                    : ukmData?.gambarTokoBW
                                            }
                                            tanggalTransaksi={tanggalTransaksi}
                                            kasir={kasir}
                                            sales={sales}
                                            total={totalPembayaran}
                                            cart={newCart}
                                            diskon={diskon}
                                            pajak={pajak}
                                            tunai={uangYangDibayar}
                                            kembalian={kembalian}
                                            namaMetode={namaMetode ?? "Tunai"}
                                            html={
                                                findCustomStruk &&
                                                findCustomStruk.html !== null
                                                    ? findCustomStruk?.html
                                                    : undefined
                                            }
                                            isPro={ukmData.isPro}
                                            noInvoice={noInvoice}
                                            subTotal={totalHarga}
                                            keterangan={keterangan}
                                            keteranganCustomAmount={
                                                ketCustomAmount
                                                    ? ketCustomAmount
                                                    : undefined
                                            }
                                            hargaCustomAmount={
                                                customAmount
                                                    ? String(
                                                          Number(customAmount),
                                                      )
                                                    : undefined
                                            }
                                            keteranganOngkir={
                                                ketOngkir
                                                    ? ketOngkir
                                                    : undefined
                                            }
                                            hargaOngkir={
                                                ongkir
                                                    ? String(Number(ongkir))
                                                    : undefined
                                            }
                                            jatuhTempo={
                                                jatuhTempo
                                                    ? moment(jatuhTempo).format(
                                                          "DD MMM YYYY",
                                                      )
                                                    : null
                                            }
                                            metodePelanggan={metodePelanggan}
                                        />
                                    }
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="center"
                                                fullWidth
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Print Ulang Struk
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>
                            ) : (
                                <BlobProvider
                                    document={
                                        <PrintStrukA4
                                            namaToko={ukmData.namaToko}
                                            alamatToko={ukmData.alamatToko}
                                            telpToko={ukmData.noTelp}
                                            gambarToko={
                                                ukmData?.gambarTokoBW === null
                                                    ? undefined
                                                    : ukmData?.gambarTokoBW
                                            }
                                            tanggalTransaksi={tanggalTransaksi}
                                            kasir={kasir}
                                            sales={sales}
                                            total={totalPembayaran}
                                            cart={newCart}
                                            diskon={diskon}
                                            pajak={pajak}
                                            tunai={uangYangDibayar}
                                            kembalian={kembalian}
                                            namaMetode={namaMetode ?? "Tunai"}
                                            html={
                                                findCustomStruk &&
                                                findCustomStruk.html !== null
                                                    ? findCustomStruk?.html
                                                    : undefined
                                            }
                                            isPro={ukmData.isPro}
                                            noInvoice={noInvoice}
                                            subTotal={totalHarga}
                                            keterangan={keterangan}
                                            keteranganCustomAmount={
                                                ketCustomAmount
                                                    ? ketCustomAmount
                                                    : undefined
                                            }
                                            hargaCustomAmount={
                                                customAmount
                                                    ? String(
                                                          Number(customAmount),
                                                      )
                                                    : undefined
                                            }
                                            keteranganOngkir={
                                                ketOngkir
                                                    ? ketOngkir
                                                    : undefined
                                            }
                                            hargaOngkir={
                                                ongkir
                                                    ? String(Number(ongkir))
                                                    : undefined
                                            }
                                            jatuhTempo={
                                                jatuhTempo
                                                    ? moment(jatuhTempo).format(
                                                          "DD MMM YYYY",
                                                      )
                                                    : null
                                            }
                                            metodePelanggan={metodePelanggan}
                                        />
                                    }
                                >
                                    {({ blob, url, loading, error }) => {
                                        return (
                                            <LoadingButton
                                                loading={loading}
                                                loadingPosition="center"
                                                fullWidth
                                                variant="contained"
                                                href={url ?? ""}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Print Ulang Struk
                                            </LoadingButton>
                                        );
                                    }}
                                </BlobProvider>
                            ))}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailInvoice;
