import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

type CoaKategori = {
    id: number;
    noAkun: string;
    namaAkun: string;
};

interface State {
    closeModalEditKategori: () => void;
    idKategori: number | string | undefined;
    isOpenModalEditKategori: boolean;
    namaKategori: string;
    openModalEditKategori: (
        id: number | undefined,
        nama: string,
        coaKategori: CoaKategori,
    ) => void;
    coaKategori?: CoaKategori;
}

interface IEditKategoriModalContext {
    children: ReactNode;
}

const EditKategoriModalContext = createContext<State | undefined>(undefined);

const EditKategoriModalProvider = ({ children }: IEditKategoriModalContext) => {
    const [isOpenModalEditKategori, setIsOpenModalEditKategori] =
        useState(false);
    const [idKategori, setIdKategori] = useState<number>();
    const [namaKategori, setNamaKategori] = useState("");
    const [coaKategori, setCoaKategori] = useState<CoaKategori>();

    const openModalEditKategori = useCallback(
        (id: number | undefined, nama: string, coaKategori: CoaKategori) => {
            setIdKategori(id);
            setNamaKategori(nama);
            setCoaKategori(coaKategori);
            setIsOpenModalEditKategori(true);
        },
        [],
    );

    const closeModalEditKategori = useCallback(() => {
        setIsOpenModalEditKategori(false);
        setNamaKategori("");
        setCoaKategori(undefined);
        setIdKategori(undefined);
    }, []);

    const value = useMemo(
        () => ({
            closeModalEditKategori,
            idKategori,
            isOpenModalEditKategori,
            namaKategori,
            openModalEditKategori,
            coaKategori,
        }),
        [
            closeModalEditKategori,
            coaKategori,
            idKategori,
            isOpenModalEditKategori,
            namaKategori,
            openModalEditKategori,
        ],
    );
    return (
        <EditKategoriModalContext.Provider value={value}>
            {children}
        </EditKategoriModalContext.Provider>
    );
};

const useEditKategoriModal = () => {
    const context = React.useContext(EditKategoriModalContext);
    if (context === undefined) {
        throw new Error(
            "useEditKategoriModal must be used within a EditKategoriModalProvider",
        );
    }
    return context;
};

export {
    EditKategoriModalContext,
    EditKategoriModalProvider,
    useEditKategoriModal,
};
