import React, { useMemo, useState } from "react";
import { useTransaksi } from "../../context/transaksiContext";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import id from "date-fns/locale/id";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { Controller, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import defaultAxios, { AxiosError } from "axios";
import { ErrorFieldResponse } from "../../constants/types";
import moment from "moment";
import axios from "../../services/axios";
import Swal from "sweetalert2";

registerLocale("id", id);
setDefaultLocale("id");

interface IModalAccPOProps {
    isOpenModalAccPO: boolean;
    closeModalAccPO: () => void;
    namaPreOrder: string;
    idPreOrder: string | number;
}

type Values = {
    transaksiId?: number | string | null;
    tanggal?: Date | null;
};

const ModalAccPO = ({
    isOpenModalAccPO,
    closeModalAccPO,
    namaPreOrder,
    idPreOrder,
}: IModalAccPOProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const { setFetchingItems } = useTransaksi();

    const initialValues: Values = useMemo(
        () => ({
            transaksiId: idPreOrder,
            tanggal: new Date(),
        }),
        [idPreOrder],
    );

    const { handleSubmit, control, setError } = useForm<Values>({
        defaultValues: initialValues,
    });

    const onSubmit = async (values: Values) => {
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("transaksiId", String(idPreOrder));
            formData.append(
                "tanggal",
                values.tanggal
                    ? moment(values.tanggal).format("DD/MM/YYYY")
                    : "",
            );

            const { data } = await axios.post("/api/transaksiaccpo", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (data.code === 200) {
                Swal.fire({
                    title: "Transaksi ditambahkan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                setFetchingItems();
                closeModalAccPO();
            }
            setIsButtonLoading(false);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    if (fieldError?.errors) {
                        Object.keys(fieldError.errors).forEach((key) => {
                            const errorMessage = fieldError.errors[key];
                            setError(key as keyof typeof initialValues, {
                                type: "manual",
                                message: errorMessage[0],
                            });
                        });
                    }
                }
            }
            console.error(error);
            setIsButtonLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            fullScreen={isPhoneScreen}
            open={isOpenModalAccPO}
            onClose={closeModalAccPO}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalAccPO}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Buat Transaksi ({namaPreOrder})
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    justifyContent="flex-start"
                    spacing={2}
                    marginTop={1}
                >
                    <Grid item xs={12} sm={6} md={5}>
                        <Typography fontWeight={500} component="div">
                            Tanggal Buat Transaksi:
                        </Typography>
                        <Controller
                            name="tanggal"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    className="range-filter"
                                    dateFormat="dd/MM/yyyy"
                                    popperPlacement="top-start"
                                    disabledKeyboardNavigation
                                    onFocus={(e) => e.target.blur()}
                                    selected={field.value}
                                    onChange={(update) => {
                                        field.onChange(update);
                                    }}
                                    monthsShown={1}
                                    customInput={
                                        <Stack
                                            display="flex"
                                            justifyContent="space-between"
                                            direction="row"
                                            // width="100%"
                                            bgcolor="#FFFF"
                                            height="40px"
                                            sx={{
                                                border: "1px solid #cbcbcb",
                                                borderRadius: "4px",
                                                alignItems: "center",
                                                padding: 2,
                                                cursor: "pointer",
                                            }}
                                        >
                                            <Typography>
                                                {field.value?.toLocaleString(
                                                    "id",
                                                    {
                                                        year: "numeric",
                                                        month: "long",
                                                        day: "numeric",
                                                    },
                                                )}
                                            </Typography>
                                            <ArrowDropDown
                                                sx={{
                                                    marginRight: "-9px",
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Typography marginTop={18}>
                    *Anda akan membuat transaksi penjualan dari Pre Order{" "}
                    {namaPreOrder}
                </Typography>
                <Box
                    mt={2}
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    justifyContent="flex-end"
                    columnGap="16px"
                >
                    <div />
                    <div />
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Simpan
                    </LoadingButton>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModalAccPO;
