import { useQuery } from "react-query";
import { ParamsGetCoa, GetCoaResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetCoa) => {
    const apiGetCoa = queryString.stringifyUrl({
        url: "api/coa",
        query: {
            size: params.size,
            page: params.page,
            search: `${params.search}`,
            kategori: params.kategori,
        },
    });
    const { data } = await axios.get<GetCoaResponse>(apiGetCoa);
    return data.data;
};

export default function useCoa(params: ParamsGetCoa) {
    return useQuery<GetCoaResponse["data"]>(["coa", params], () =>
        handleRequest(params),
    );
}
