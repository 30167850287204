import { useInfiniteQuery } from "react-query";
import {
    ParamsGetItem,
    GetItemResponse,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetItem) => {
    const apiGetItem = queryString.stringifyUrl({
        url: "api/item",
        query: {
            ukmId: params.ukmId,
            search: `${params.search}`,
            kategori: params.kategori,
            size: params.size,
            page: params.page,
        },
    });
    const { data } = await axios.get<GetItemResponse>(apiGetItem);
    return {
        results: data.data,
        nextPage: params.page + 1,
        totalPages: Math.ceil(data.data.totalElements / data.data.size),
    };
};

export default function useInfiniteItem(params: ParamsGetItem) {
    return useInfiniteQuery(
        ["item", params],
        ({ pageParam = 1 }) => {
            // console.log(pageParam);
            return handleRequest({ ...params, page: pageParam });
        },
        {
            enabled: !!params?.ukmId,
            // getNextPageParam: (lastPage) => lastPage.nextId ?? false,
            getNextPageParam: (lastPage, pages) => {
                if (lastPage.nextPage <= lastPage.totalPages)
                    return lastPage.nextPage;
                return undefined;
            },
        },
    );
}
